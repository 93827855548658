export const stringAsObject = (string) => {
  if (!string) {
    return {};
  }

  try {
    return JSON.parse(string);
  } catch (error) {
    console.log("Błąd parsowania opisu produktu:", error);
    return {};
  }
};
