import React, { useState } from "react";
import "../../styles/components/exampleWorkoutProgram/ExampleWorkoutContent.css";
import ExampleWorkoutProgram from "../../components/ExampleWorkoutProgram/ExampleWorkoutProgram";

const ExampleWorkoutHeader = () => {
  return (
    <div className="text-container">
      <p>
        <snap className="montserrat-md">Treningi</snap>
      </p>
      <h1>
        <snap className="main-heading display-xl">
          {" "}
          Poznaj wybrane treningi{" "}
        </snap>
        <br />
        <snap className="italic-text highlight-text display-xl">
          Shape Factor
        </snap>
      </h1>
    </div>
  );
};

const ExampleWorkoutContent = () => {
  return (
    <div className="workout-container">
      <ExampleWorkoutHeader />
      <ExampleWorkoutProgram />
    </div>
  );
};

export default ExampleWorkoutContent;
