import "../../../styles/components/programs/ProgramsList.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgramsCell from "./ProgramsCell";
import { fetchPrograms } from "../../../services/training/ProgramService";
import { sendEventToGTM } from "../../../analytics/AnalyticsManager";

const ProgramsList = ({ selectedCategory }) => {
  const [allPrograms, setAllPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getPrograms = async () => {
      const fetchedPrograms = await fetchPrograms();
      setAllPrograms(fetchedPrograms);
      setLoading(false);
    };

    getPrograms();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const filtered = allPrograms.filter(
        (program) => program.location === selectedCategory,
      );
      setFilteredPrograms(filtered);
    } else {
      setFilteredPrograms(allPrograms);
    }
  }, [selectedCategory, allPrograms]);

  const handleCellClick = (programId) => {
    sendEventToGTM({
      eventName: "navigation",
      action: "Go to",
      label: "/module",
      value: "1",
    });
    navigate(`/module?id=${programId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="programs-list">
      {filteredPrograms
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((program) => (
          <ProgramsCell
            key={program.id}
            title={program.name}
            description={program.description}
            image={program.new_image2}
            background={program.background2}
            onClick={() => handleCellClick(program.id)}
          />
        ))}
    </div>
  );
};

export default ProgramsList;
