import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../../services/authorization/signIn";
import TextField from "../../components/textFields/TextField";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { resetPassword } from "../../services/authorization/passwordReset";
import "../../styles/Screens/PasswordResetScreen/PasswordResetScreen.css";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const PasswordResetScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    setEmail("");
    try {
      await resetPassword(email);
      console.log("password-reset successful");
      alert("Sukces, sprawdź skrzynkę e-mail");
      sendEventToGTM({
        eventName: "navigation",
        action: "Go to",
        label: "/signin",
        value: "1",
      });
      navigate("/signin");
    } catch (err) {
      console.log(err);
      setError("Nie udało się, sprawdz dane i spróbuj ponownie");
    }
  };

  return (
    <div className="password-reset-container">
      <div className="password-reset-left">
        <div className="logo-container">
          <img
            src="/logo-default.svg"
            alt="Shape Factor Logo"
            className="logo"
          />
        </div>
        <div style={{ flexGrow: 0.7 }}></div>
        <div className="form-container">
          <div className="form-cntr">
            <div className="password-reset-form mb-5">
              <h2 className="mb-2 display-lg">Zresetuj swoje hasło</h2>
              <p className="montserrat-sm mb-3">
                Wpisz adres email, na który wyślemy link resetujący hasło
              </p>
            </div>

            <form className="password-reset-form">
              <div className="mt-4">
                <TextField
                  label="Adres email"
                  placeholder="Wpisz adres email"
                  value={email}
                  onChange={setEmail}
                  required={true}
                />

                <div className="mt-2">
                  <PrimaryButton
                    text="Wyślij link"
                    onClick={handlePasswordReset}
                  />

                  {error && <p className="error-message">{error}</p>}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div className="mt-5">
          <p className="password-reset-footer text-sm">
            Masz pytanie?{" "}
            <a className="text-sm semibold" href="/login">
              pomoc@shapefactor.pl
            </a>
          </p>
        </div>
        <div style={{ flexGrow: 1 }}></div>
      </div>

      <div className="password-reset-right">
        <img
          src="/password-reset/password_reset_default_img.svg"
          alt="Promo"
          className="promo-image"
        />
        <div className="app-promo-section">
          <h2 className="display-md">
            <span className="highlight-text italic-text">Każdy poziom.</span>{" "}
            Certyfikowany Trener osobisty w Twoim telefonie.
          </h2>
          <p className="text-md medium-italic mt-3 mb-5">
            Pobierz aplikację Shape Factor na swój telefon
          </p>
          <div className="app-store-buttons">
            <img
              src="/login/AppStore.svg"
              alt="Download on the App Store"
              className="app-badge"
            />
            <img
              src="/login/GooglePlay.svg"
              alt="Get it on Google Play"
              className="app-badge"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetScreen;
