export const fetchGifts = () => {
  const gifts = [
    {
      id: 1,
      image: "/paywall/rhs_paywall_gift.svg",
      planText: "planu kwartalnego",
      highlited: "Darmowa",
      title: "długa guma oporowa Shape Factor",
      description:
        "Otrzymujesz długą gumę oporową wykonaną z wysokiej jakości certyfikowanego lateksu o wysokim współczynniku wytrzymałości.",
    },
    {
      id: 2,
      image: "/paywall/lhs_paywall_gift.svg",
      planText: "planu rocznego",
      highlited: "Darmowy",
      title:
        "pakiet gum oporowych (zestaw 5 gum minibands i długa guma oporowa)",
      description:
        "Wysokiej jakości sprzęt treningowy w postaci długiej gumy oporowej oraz gum minibands o różnej sile oporu, dzięki czemu dostosujesz każdy trening do swoich aktualnych możliwości i będziesz w stanie progresować.",
    },
  ];

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(gifts);
    }, 1000);
  });
};
