import { collection, getDocs } from "firebase/firestore";
import { db } from "../authorization/firebase.config";
import { getRequest, putRequest } from "../HttpService";

export const fetchUser = async () => {
  try {
    const response = await getRequest("/api/user/login");
    return response;
  } catch (error) {
    return error;
  }
};

export const updateUser = async (data) => {
  try {
    const body = { full_name: data.full_name, phone_number: data.phone_number };
    const response = await putRequest("/api/user/update", body);
    return response;
  } catch (error) {
    return error;
  }
};
