import { collection, getDocs, query, where, doc } from "firebase/firestore";
import { db } from "../authorization/firebase.config";

export const fetchWorkoutsForProgram = async (programId) => {
  try {
    const programRef = doc(db, "programs", programId);

    const workoutsQuery = query(
      collection(db, "workouts"),
      where("program", "==", programRef),
    );

    const workoutsSnapshot = await getDocs(workoutsQuery);

    const workoutsList = workoutsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return workoutsList;
  } catch (error) {
    console.error("Error fetching workouts for program:", error);
    return [];
  }
};

export const fetchWorkoutFormWorkout = async (workoutId) => {
  try {
    const programRef = doc(db, "workouts", workoutId);

    const workoutsQuery = query(
      collection(db, "exercises"),
      where("workoutReference", "==", programRef),
    );

    const workoutsSnapshot = await getDocs(workoutsQuery);

    const workoutsList = workoutsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return workoutsList;
  } catch (error) {
    console.error("Error fetching workouts for program:", error);
    return [];
  }
};
