import React, { useState, useEffect } from "react";
import "../../../styles/Screens/AccountDetails/AccountDetails.css";
import { ArrowRight } from "react-feather";
import InfoRow from "../../../components/InfoRow/InfoRow";
import Separator from "../../../components/Separator";
import EditProfileModal from "../../../components/Modals/EditProfileModal";
import { resetPassword } from "../../../services/authorization/passwordReset";

const AccountContent = ({ user, onUserUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalUser, setModalUser] = useState(null);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      setFullName(user.full_name || "");
      setPhoneNumber(user.phone_number || "");
      setEmail(user.email || "");
    }
  }, [user]);

  const openModal = () => {
    setModalUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      await resetPassword(email);
      alert("Sukces, sprawdź skrzynkę e-mail");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="account-content">
      <EditProfileModal
        isOpen={isModalOpen}
        onClose={closeModal}
        user={modalUser}
        onUserUpdate={onUserUpdate}
      />
      <div className="section">
        <div className="section-left">
          <h3 className="text-lg semibold">Dane personalne</h3>
          <p className="text-s medium">
            Edytuj w łatwy sposób swoje dane personalne do konta Shape Factor
          </p>
          <div onClick={openModal} className="edit-link text-md semibold">
            Edytuj <ArrowRight size={16} />
          </div>
        </div>
        <div className="section-right">
          <InfoRow label="Imię i nazwisko" value={fullName} />
          <Separator margin="0px 0px 16px 0px" />
          <InfoRow label="Numer telefonu" value={phoneNumber} />
        </div>
      </div>
      <div className="spacer">
        <Separator margin="0px 0px 0px 0px" />
      </div>
      <div className="section">
        <div className="section-left">
          <h3 className="text-lg semibold">Bezpieczeństwo konta</h3>
          <p className="text-s medium">
            Edytuj w łatwy sposób hasło do konta Shape Factor
          </p>
          <a
            className="edit-link text-md semibold"
            onClick={(e) => handlePasswordReset(e)}
          >
            Zmień hasło <ArrowRight size={16} />
          </a>
        </div>
        <div className="section-right">
          <InfoRow label="Adres e-mail" value={email} />
          <Separator margin="0px 0px 16px 0px" />
          <InfoRow label="Hasło" value="************" />
        </div>
      </div>
    </div>
  );
};

export default AccountContent;
