import React from "react";
import "../../styles/components/DetailProductParameters/DetailProductParameters.css";
import { Check } from "react-feather";

const DetailProductParameters = ({ title = "Parametry", parameters = [] }) => {
  return (
    <div className="detail-product-parameters">
      <h2 className="text-md detail-product-parameters-title">{title}</h2>
      <ul className="detail-product-parameters-items">
        {parameters.map((param, index) => (
          <li key={index} className="detail-product-parameters-item">
            <Check
              color="var(--color-primary)"
              className="detail-product-parameters-item-desc"
            />{" "}
            <p className="text-md blue-text">{param}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DetailProductParameters;
