import React from "react";
import { Link } from "react-router-dom";
import "../../styles/components/bottomBar/BottomBar.css";

const BottomBar = () => {
    const handleOpenFile = (filePath) => {
        window.open(`${process.env.PUBLIC_URL}${filePath}`, '_blank');
    };

    return (
        <nav className="bottom">
            <div className="bottom-logo">
                <img src="/logo-default.svg" alt="Shape Factor Logo" className="logo" />
            </div>
            <div>
                <p className="text-xs">© SHAPEFACTOR 2024. Wszystkie prawa zastrzeżone</p>
            </div>
            <div className="bottom-links">
                <p className="text-xs">
                    Cookies
                </p>
                <p
                    className="text-xs"
                    onClick={() => handleOpenFile('/privacyPolicy.pdf')}
                >
                    Polityka prywatności
                </p>
                <p
                    className="text-xs"
                    onClick={() => handleOpenFile('/appTerms.pdf')}
                >
                    Regulamin
                </p>
            </div>
        </nav>
    );
};



export default BottomBar


