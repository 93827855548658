import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { signUp, AuthProviders } from '../../services/authorization/signUp';
import TextField from '../../components/textFields/TextField';
import SecuredTextField from '../../components/textFields/PasswordTextField';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import VStack from '../../components/stacks/VStack';
import HStack from '../../components/stacks/HStack';
import Separator from '../../components/Separator';
import SocialButton from '../../components/buttons/social/SocialButton';
import Checkbox from '../../components/checkboxes/Checkbox';
import '../../styles/Screens/RegisterScreen/RegisterScreen.css';
import { tryToSignIn } from '../../services/authorization/signIn';
import { sendEventToGTM } from '../../analytics/AnalyticsManager';

const RegisterScreen = () => {
    const navigate = useNavigate();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [marketingAccepted, setMarketingAccepted] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValidationError, setPasswordValidationError] = useState(false)
    const [didCatchError, setCatchedError] = useState(false)
    const [error, setError] = useState('');

    const termsAndPrivacySnap = (
        <span className={`text-s ${!termsAccepted && didCatchError ? 'failure-text' : ''}`}>
          Akceptuję{' '}
          <span
            className="text-s link"
            onClick={() => window.open(`${process.env.PUBLIC_URL}/appTerms.pdf`, '_blank')}
            tabIndex={0}
          >
            Regulamin
          </span>{' '}
          oraz{' '}
          <span
            className="text-s link"
            onClick={() => window.open(`${process.env.PUBLIC_URL}/privacyPolicy.pdf`, '_blank')}
            tabIndex={0}
          >
            Politykę Prywatności
          </span>
        </span>
      );    

    const handleRegister = async (e, type) => {
        e.preventDefault();
        setError('');
        setPasswordValidationError(false);

        if (type == AuthProviders.STANDARD) {
            if (password.length == 0) {
                setPasswordValidationError(true);
            }

            if (!termsAccepted) {
                setCatchedError(true);
                return
            }
        }

        try {
            let registerData = await signUp(email, password, type);
            console.log(registerData.message);
            let loginParams = await tryToSignIn(); 

            if (loginParams.data.subscriptions == true) {
                sendEventToGTM({
                    eventName: 'navigation',
                    action: 'Go to',
                    label: '/dashboard',
                    value: '1',
                });
                navigate('/dashboard')
            } else {
                sendEventToGTM({
                    eventName: 'navigation',
                    action: 'Go to',
                    label: '/paywall',
                    value: '1',
                });
                navigate('/paywall')
            }
        } catch (err) {
            let errorMessage = "Nie udało się zarejestrować, sprawdź dane i spróbuj ponownie.";

            if (err.response && err.response.data) {
              const firstMessage = err.response.data.message[0]?.message;
              if (firstMessage) {
                errorMessage = firstMessage;
              }
            }

            setError(errorMessage);
        }
    };

    const onPasswordUpdate = (v) => {
        setPassword(v);
        setPasswordValidationError(false);
    };

    return (
        <div className="register-container">
            <div className="register-left">
                <div className="logo-container">
                    <Link to={"/"}>
                    <img src="/logo-default.svg" alt="Shape Factor Logo" className="logo" />
                    </Link>
                </div>
                <div style={{ flexGrow: 0.7 }}></div>
                <div className="form-container">
                    <div>
                        <div className='register-form mb-5'>
                            <h2 className='mb-2 display-lg'>Wypełnij formularz</h2>
                            <p className='montserrat-sm mb-3'>Zacznij dziś z Shape Factor!</p>
                        </div>

                        <form className="register-form">
                            <div className='mt-4'>
                                <TextField
                                    label="Adres email"
                                    placeholder="Wpisz adres email"
                                    value={email}
                                    onChange={setEmail}
                                    required={true}
                                />
                                <SecuredTextField
                                    label="Hasło"
                                    placeholder="*******"
                                    onChange={onPasswordUpdate}
                                    required={true}
                                    validationError={passwordValidationError}
                                    errorMessage='To pole jest wymagane'
                                />

                                <div className='mb-3 mt-1'>
                                    <p className='text-sm regular'>
                                        Hasło musi składać się przynajmniej z 8 znaków,w tym małe i duże litery, cyfry, znaki specjalne
                                    </p>
                                </div>

                                <div className='mt-5 mb-5'>
                                    <Checkbox
                                        snap={termsAndPrivacySnap}
                                        checked={termsAccepted}
                                        onChange={() => setTermsAccepted(!termsAccepted)}
                                    />
                                    <Checkbox
                                        snap={<snap className='text-s'>Zgoda na otrzymywanie informacji marketingowych</snap>}
                                        checked={marketingAccepted}
                                        onChange={() => setMarketingAccepted(!marketingAccepted)}
                                    />
                                </div>
                                <div className='mt-3'></div>
                                <PrimaryButton text="Zarejestruj się" type="submit" onClick={(e) => handleRegister(e, AuthProviders.STANDARD)} />

                                {error &&
                                    <div className='mt-4'>
                                        {error && <p className="error-message">{error}</p>}
                                    </div>
                                }

                            </div>
                        </form>

                        <div className='register-form mt-3'>
                            <HStack>
                                <Separator />
                                <snap className='text-xs medium'>Lub</snap>
                                <Separator />
                            </HStack>
                        </div>

                        <div className='register-form mt-3'>
                            <VStack gap='12px'>
                                <SocialButton title={"Kontynuuj z Google"} imageSource={'./socials/google-logo.svg'} onClick={(e) => handleRegister(e, AuthProviders.GOOGLE)} />
                                <SocialButton title={"Kontynuuj z Apple"} imageSource={'./socials/apple-logo.svg'} onClick={(e) => handleRegister(e, AuthProviders.APPLE)} />
                            </VStack>
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    <p className="register-footer text-sm regular">
                        Masz juz konto? <Link className='text-sm semibold' to={'/signin'}>Zaloguj się</Link>
                    </p>
                    <p className="register-footer text-sm regular">
                        Masz pytanie? <a className='text-sm semibold' onClick={(e) => { window.location.href = 'mailto:pomoc@shapefactor.pl'; }}>pomoc@shapefactor.pl</a>
                    </p>
                </div>
                <div style={{ flexGrow: 1 }}></div>
            </div>

            <div className="register-right">
                <img src="/logo-default.jpg" alt="Promo" />
                <div class="register-right-info">
                    <h2 className='display-md'>“Dołącz do Shape Factor dzisiaj i otrzymaj darmowy zestaw gum treningowych o wartości 209 zł</h2>
                    <p className='text-md italic blue-text'>Dagmara Wawrzyniak</p>
                </div>
            </div>
        </div>
    );
};

export default RegisterScreen