import React, { useState, useEffect } from "react";
import "../../styles/Screens/TrainingModuleScreen/TrainingModuleScreen.css";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar";
import { useLocation, useNavigate } from "react-router-dom";
import BottomBar from "../../components/BottomBar/BottomBar";
import AppPromoSection from "../../components/promo/AppPromoSection";
import ProgramDetails from "../../components/Traning/ProgramDetails/ProgramDetails";
import { fetchProgram } from "../../services/training/ProgramService";
import Footer from "../../components/Footer/Footer";
import TrainingList from "../../components/Traning/TrainingDetails/TrainingContent";
import { fetchWorkoutsForProgram } from "../../services/training/WorkoutsService";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const TrainingModuleScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const programId = searchParams.get("id");

  const [loading, setLoading] = useState(true);
  const [program, setProgram] = useState(null);
  const [trainingId, setTrainingId] = useState(null);

  useEffect(() => {
    const getProgram = async () => {
      const fetchedProgram = await fetchProgram(programId);
      setProgram(fetchedProgram);
    };

    const getWorkouts = async () => {
      const fetchedData = await fetchWorkoutsForProgram(programId);
      const id = fetchedData.sort((a, b) => (a.order > b.order ? 1 : -1))[0].id;
      setTrainingId(id || "");
    };
    setLoading(true);
    getProgram();
    getWorkouts();
    setLoading(false);
  }, [programId]);

  const handleCellClick = (e) => {
    console.log("onClicked");
    e.preventDefault();
    sendEventToGTM({
      eventName: "navigation",
      action: "Go to",
      label: "/workout",
      value: "1",
    });
    navigate(`/workout?idx=${trainingId}`);
  };

  if (!program) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <TopNavigationBar />
      </div>
      <div className="nav-title display-xl medium">
        <h2>{program.name}</h2>
      </div>
      <div className="mb-5">
        <ProgramDetails
          image={program.background}
          modulesCount={8}
          trainingHours={6}
          difficultyLevel="Średniozaawansowany"
          description={program.description}
          buttonText="Zacznij trening"
          onButtonClick={(e) => {
            handleCellClick(e);
          }}
        />
      </div>
      <div>
        <TrainingList programId={programId} />
      </div>
      <div>
        <AppPromoSection />
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <BottomBar />
      </div>
    </div>
  );
};

export default TrainingModuleScreen;
