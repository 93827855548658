import React from "react";
import "../../styles/components/SubscriptionPlan/SubscriptionPlan.css";
import { Check, Circle } from "react-feather";

const SubscriptionPlan = ({
  title,
  price,
  duration,
  durationInfo = "miesiąc",
  details,
  isSelected,
  onClick,
}) => {
  return (
    <div
      className={`subscription-plan ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className={`plan-header ${isSelected ? "selected" : ""}`}>
        <div className="plan-header-title">
          <h3 className="text-lg semibold">{title}</h3>
          {isSelected && (
            <span className="subscription-label text-md regular blue-text">
              Twoja subskrypcja
            </span>
          )}
        </div>

        {isSelected && <span className="checkmark">&#10003;</span>}
      </div>

      <div className="plan-body">
        <div className="plan-price">
          <span className="price display-lg semibold blue-text">
            {price} zł
          </span>
          <span className="period text-sm gray-text medium">
            /{durationInfo}
          </span>
        </div>
        <p className="total-cost text-sm gray-text medium">{duration}</p>
        <div className="plan-details">
          {details.map((detail, index) => (
            <div key={index} className="detail-item">
              <Check color="var(--color-primary)" />{" "}
              <snap className="text-md medium">{detail}</snap>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
