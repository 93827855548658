import React, { useState, useEffect } from "react";
import "../../styles/components/freeGifts/FreeGiftsContent.css";
import { Gift } from "react-feather";
import { fetchGifts } from "../../services/offer/GiftService";
import FreeGiftsCell from "./FreeGiftsCell";

const FreeGiftHeader = () => {
  return (
    <div className="free-gift-header">
      <div className="icon-wrapper">
        <Gift className="gift-icon" size={24} />
      </div>
      <h1 className="main-heading display-xl">
        Dostajesz zestaw gum oporowych <br /> do ćwiczeń -{" "}
        <span className="highlight-text display-xl">bezpłatnie</span>
      </h1>
      <p className="subheading text-md">
        Oraz <span className="highlight-bold text-md semibold">dostęp</span> do
        biblioteki wydań elektronicznych publikacji opracowanych przez <br />
        <snap className="text-md higlight-bold semibold">
          Dagmarę Wawrzyniak - certyfikowaną trenerkę fitness.
        </snap>
      </p>
      <p className="call-to-action text-xl">Wybierz swój prezent:</p>
    </div>
  );
};

const FreeGiftList = () => {
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchGifts().then((data) => {
      setGifts(data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="free-gifts-list">
      {gifts.map((gift) => (
        <FreeGiftsCell
          key={gift.id}
          planText={gift.planText}
          highlited={gift.highlited}
          title={gift.title}
          subtitle={gift.subtitle}
          image={gift.image}
          description={gift.description}
          onClick={() => console.log(`${gift.title} claimed`)}
        />
      ))}
    </div>
  );
};

const FreeGiftContent = () => {
  return (
    <div>
      <FreeGiftHeader />
      <FreeGiftList />
    </div>
  );
};

export default FreeGiftContent;
