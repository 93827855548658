import React, { useState } from "react";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar";
import AppPromoSection from "../../components/promo/AppPromoSection";
import BottomBar from "../../components/BottomBar/BottomBar";
import "../../styles/Screens/BlogScreen/BlogScreen.css";
import LatestArticleCell from "../../components/LatestArticleCell/LatestArticleCell";
import LatestArticleGrid from "../../components/LatestArticleGrid/LatestArticleGrid";
import FeaturedArticleGrid from "../../components/LatestArticleGrid/ArticleGrid";
import Footer from "../../components/Footer/Footer";
import blogData from "../../styles/Screens/BlogPageScreen/blogData";

const uniqueTags = [
  ...new Set(Object.values(blogData).map((article) => article.tag)),
];
const BlogFilter = ({ filters, selectedFilter, onFilterChange }) => (
  <div className="main-blog-filter-container">
    {filters.map((filter, index) => (
      <button
        key={index}
        onClick={() => onFilterChange(filter)}
        className="text-s medium"
        style={{
          padding: "8px 16px",
          fontSize: "14px",
          color: selectedFilter === filter ? "white" : "var(--color-blue)",
          backgroundColor:
            selectedFilter === filter ? "var(--color-blue)" : "#f0f4f8",
          border: "1px solid #d3d3d3",
          borderRadius: "20px",
          cursor: "pointer",
          whiteSpace: "nowrap", // Prevents text from wrapping
          transition: "background-color 0.3s, color 0.3s",
        }}
      >
        {filter}
      </button>
    ))}
  </div>
);

const BlogScreen = () => {
  const [selectedFilter, setSelectedFilter] = useState("Wszystkie wpisy");

  // Filter articles based on the selected tag
  const filteredArticles =
    selectedFilter === "Wszystkie wpisy"
      ? blogData
      : Object.fromEntries(
          Object.entries(blogData).filter(
            ([, article]) => article.tag === selectedFilter,
          ),
        );

  return (
    <div>
      <TopNavigationBar />
      <div
        style={{
          backgroundColor: "#F2F4F7",
        }}
      >
        <div className="main-blog-screen-top-container">
          <p className="text-md semibold main-color mb-4">Blog Shape Factor</p>
          <h1 className="display-xl medium">Odkryj tajniki fitnessu i</h1>
          <h1 className="display-xl medium main-color">Zdrowego Stylu Życia</h1>
          <div className="mt-5">
            <FeaturedArticleGrid />
          </div>
        </div>
      </div>

      <div className="main-blog-screen-container">
        <div className="main-blog-screen-contianer-latest-article">
          <p className="display-md medium blue-text mb-3">Ostatnie artykuły</p>
          <BlogFilter
            filters={["Wszystkie wpisy", ...uniqueTags]}
            selectedFilter={selectedFilter}
            onFilterChange={setSelectedFilter}
          />
        </div>
      </div>
      <LatestArticleGrid articles={filteredArticles} />
      <div>
        <AppPromoSection />
      </div>
      <Footer />
      <BottomBar />
    </div>
  );
};

export default BlogScreen;
