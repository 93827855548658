import React from "react";
import "../../styles/components/InfoRow/InfoRow.css";

const InfoRow = ({ label, value }) => {
  return (
    <div className="info-row">
      <span className="text-s medium">{label}</span>
      <span className="text-x semibold blue-text">{value}</span>
    </div>
  );
};

export default InfoRow;
