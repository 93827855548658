import React from "react";
import "../../styles/components/productCell/ProductCell.css";
import PrimaryButton from "../buttons/PrimaryButton";
import { FaStar } from "react-icons/fa";
import { stringAsObject } from "../../helpers/parser";

const ProductCell = ({ product, onClick, onCellClick }) => {
  const { image, name, new_price, description } = product;

  return (
    <div className="product-cell" onClick={onCellClick}>
      <div className="product-image-container mb-3">
        <img src={image} alt={name} className="product-image" />
      </div>
      <div className="mb-3">
        <h3 className="display-xs medium blue-text mb-2">{name}</h3>
        {/* Sprawdzamy czy 'description' istnieje oraz czy ma właściwość 'description' */}
        <p className="product-description montserrat-sm medium ">
          {stringAsObject(description).description || ""}
        </p>
        {/* <div className="product-rating mt-1"> */}
        {/* <FaStar size={16} color={"#FBB040"} />  */}
        {/* <span className="rating-value text-s semibold blue-text">5.0</span>
                    <span className="rating-reviews text-s gray-text">(230 Opinii)</span> */}
        {/* </div> */}
      </div>
      <div className="product-prices">
        <span className="new-price text-xl semibold">{new_price} zł</span>
      </div>
      <div className="product-button-content">
        <PrimaryButton text={"Dodaj do koszyka"} onClick={onClick} />
      </div>
    </div>
  );
};

export default ProductCell;
