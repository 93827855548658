import React from "react";
import "../../styles/components/stacks/VStack.css";

const VStack = ({ children, gap = "16px" }) => {
  return (
    <div className="vstack" style={{ gap }}>
      {children}
    </div>
  );
};

export default VStack;
