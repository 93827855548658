import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../../styles/Screens/EditProfileModal/EditProfileModal.css";
import { X } from "react-feather";
import TextField from "../textFields/TextField";
import PrimaryButton from "../buttons/PrimaryButton";
import { updateUser } from "../../services/user/userService";

const EditProfileModal = ({ isOpen, onClose, user, onUserUpdate }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (user) {
      setFullName(user.full_name || "");
      setPhoneNumber(user.phone_number || "");
    }
  }, [user]);

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const updatedUser = {
      full_name: fullName,
      phone_number: phoneNumber,
    };

    const updateUserInfo = async () => {
      const data = await updateUser(updatedUser);
      if (data) {
        await handleClose();
        window.location.reload();
      }
    };

    updateUserInfo();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={`edit-profile-modal ${isClosing ? "modal-closing" : ""}`}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h2>Edytuj dane personalne</h2>
        <button onClick={handleClose} className="close-button">
          <X size={24} color="#B46EF9" />
        </button>
      </div>

      <form className="edit-profile-form">
        <TextField
          className="input-field"
          label="Imię i nazwisko"
          value={fullName}
          onChange={setFullName}
          required={true}
        />
        <TextField
          className="input-field"
          label="Numer telefonu"
          value={phoneNumber}
          onChange={setPhoneNumber}
          required={true}
        />

        {/* <button type="submit" className="save-button">Zapisz</button> */}
        <div className="mt-3">
          <PrimaryButton text={"Zapisz"} onClick={(e) => handleSave(e)} />
        </div>
      </form>
    </Modal>
  );
};

export default EditProfileModal;
