import React, { useState, useEffect } from "react";
import "../../styles/components/dropdownMenu/DropdownMenu.css";
import SecondaryButton from "../buttons/SecondaryButton";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../services/authorization/logOut";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      await logout();
      sendEventToGTM({
        eventName: "navigation",
        action: "Go to",
        label: "/",
        value: "1",
      });
      navigate("/");
    } catch {
      console.log("Error");
    }
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown">
      <SecondaryButton
        text={isOpen ? "Profil ▴" : "Profil ▾"}
        onClick={toggleDropdown}
      />
      {isOpen && (
        <div className="dropdown-menu">
          <ul>
            <Link to="/profile?=account">
              <li>
                <img src={"./icons/dropdown-user.svg"} alt="Edycja danych" />{" "}
                Edycja danych
              </li>
            </Link>
            <Link to="/profile?=orders">
              <li>
                <img src={"./icons/dropdown-order.svg"} alt="Zamówienia" />{" "}
                Zamówienia
              </li>
            </Link>
            <Link to="/profile?=subscriptions">
              <li>
                <img src={"./icons/dropdown-sub.svg"} alt="Subskrypcje" />{" "}
                Subskrypcje
              </li>
            </Link>
            <Link to="/app?=logout">
              <li className="logout" onClick={handleLogout}>
                <img src={"./icons/dropdown-logout.svg"} alt="Wyloguj się" />{" "}
                Wyloguj się
              </li>
            </Link>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
