import React, { useState, useEffect } from "react";
import { addProduct } from "../../services/basket/basketService";
import { useCart } from "../../providers/CartContext";
import { useAuth } from "../../services/authorization/authContext";
import { useLocation } from "react-router-dom";
import "../../styles/Screens/ProductDetailsScreen/ProductDetailsScreen.css";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar";
import Footer from "../../components/Footer/Footer";
import BottomBar from "../../components/BottomBar/BottomBar";
import AppPromoSection from "../../components/promo/AppPromoSection";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import AddToCartModal from "../../components/addToCardModal/AddToCardModal";
import { getProductDetails } from "../../services/shop/productService";
import DetailProductParameters from "../../components/detailProductParameters/DetailProductParameters";

const ProductDetailsScreen = () => {
  const { user, ldng } = useAuth();
  const { updateCartCount, itemsInBasket } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [modalVisible, setModalVisible] = useState(true);
  const [addedProduct, setAddedProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [parsedDescription, setParsedDescription] = useState({});

  const location = useLocation();

  useEffect(() => {
    const fetchProductDetails = async (id) => {
      try {
        const data = await getProductDetails(id);
        const productData = data[0] || {};

        let parsedDescriptionData = {};
        if (productData.description) {
          try {
            parsedDescriptionData = JSON.parse(productData.description);
            window.scrollTo(0, 0);
          } catch (error) {
            console.log("Błąd parsowania opisu produktu:", error);
          }
        }

        setProduct(productData);
        setParsedDescription(parsedDescriptionData);
      } catch (error) {
        console.log("Błąd pobierania danych produktu:", error);
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    if (id) {
      setProductId(id);
      fetchProductDetails(id);
    }
  }, [location]);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddClicked = async (e) => {
    e.preventDefault();

    try {
      const id = productId;
      const data = await addProduct(id, user);
      updateCartCount(itemsInBasket + 1);
      setAddedProduct(product);
      setModalVisible(true);

      setTimeout(() => {
        setModalVisible(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <TopNavigationBar />
      </div>
      <div className="product-details-screen">
        <div className="product-details-screen-main-section">
          <div className="product-details-screen-image-section">
            <img
              src={product.image}
              alt="Mata Treningowa Shape Factor"
              className="product-details-screen-image"
            />
          </div>

          <div className="product-details-screen-info-section">
            {/* Display Medium/Font */}
            <h1 className="display-md product-details-screen-title blue-text">
              {product.name}
            </h1>

            <div className="product-details-screen-rating">
              {/* <span className="product-details-screen-star">⭐⭐⭐⭐⭐</span> */}
              {/* <span className="text-s rating-value">5.0</span> */}
            </div>

            <p className="text-s medium product-details-screen-description">
              {parsedDescription.description}
            </p>

            <div>
              <div className="text-lg semibold blue-text medium product-details-screen-price">
                {product.new_price} zł
              </div>
              {/* <p className='text-md semibold blue-text'>Ilość</p> */}
            </div>

            <div className="product-details-screen-quantity-selector">
              {/* <label htmlFor="quantity">Ilość</label> */}
              {/* <input */}
              {/* id="quantity" */}
              {/* type="number" */}
              {/* value={quantity} */}
              {/* onChange={handleQuantityChange} */}
              {/* min="1" */}
              {/* className="product-details-screen-quantity-input" */}
              {/* /> */}
            </div>

            <PrimaryButton
              text={"Dodaj do koszyka"}
              onClick={(e) => handleAddClicked(e)}
            />
            {/* <button className="product-details-screen-add-to-cart-button" onClick={handleAddToCart}>
                            Dodaj do koszyka
                        </button> */}
          </div>
        </div>

        <div className="product-details-screen-description-section">
          <h2 className="inter-xl mt-5">Opis</h2>
          <p className="montserrat-sm mt-5">
            {parsedDescription.subInfo}
            {/* Mata do jogi i pilatesu jest wykonana z trwałego termoplastycznego kauczuku <a href="#">TPE</a>.
                        Ten wysokogatunkowy materiał jest bezpieczny dla środowiska. Do tego cechuje się lekkością,
                        doskonałą przyczepnością i świetnymi właściwościami antypoślizgowymi. Nie będzie przesuwał się,
                        zwijał czy rolował po podłożu na sali treningowej, czy w domu.
                        Materiał TPE jest sprężysty i elastyczny. Jego miękką powierzchnię wytłoczono diamentowym wzorem.
                        Tym samym kauczuk zapewnia doskonały komfort podczas ćwiczeń i wchodzenia w kolejne pozycje. Co
                        więcej, mata do jogi fitnessu <strong>180 × 60 cm</strong> jest niezwykle lekka oraz odporna na
                        niskie i wysokie temperatury. */}
          </p>
        </div>

        {parsedDescription.parameters &&
          parsedDescription.parameters.length > 0 && (
            <div>
              <DetailProductParameters
                parameters={parsedDescription.parameters}
              />
            </div>
          )}
      </div>

      <div>
        <AppPromoSection />
      </div>
      <div>
        <Footer />
      </div>
      <BottomBar />
      {modalVisible && addedProduct && (
        <AddToCartModal
          product={addedProduct}
          onClose={() => setModalVisible(false)}
        />
      )}
    </div>
  );
};

export default ProductDetailsScreen;
