import React from "react";
import { useNavigate } from "react-router-dom";
import "./LatestArticleCell.css";
import { ArrowRight } from "react-feather";

const LatestArticleCell = ({ id, imageSrc, label, title, description }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/id/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="latest-article-cell"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div className="latest-article-image-container">
        <img
          src={imageSrc}
          alt="Article visual"
          className="latest-article-image"
        />
        <span className="latest-article-label-tag text-s medium">{label}</span>
      </div>
      <div className="latest-article-content">
        <div className="latest-article-title-container">
          <h3 className="latest-article-title display-xs medium blue-text">
            {title}
          </h3>
          <span className="latest-article-arrow">
            <ArrowRight size={24} />
          </span>
        </div>
        <p className="latest-article-description montserrat-sm medium">
          {description}
        </p>
      </div>
    </div>
  );
};

export default LatestArticleCell;
