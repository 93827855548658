import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { OfferCell, MobileOfferCell } from "./OfferCell";
import {
  fetchPlanFeatures,
  createNewSubscriptionsIntent,
} from "../../services/offer/OfferService";

const OfferContent = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 846 });

  useEffect(() => {
    const getSubscriptions = async () => {
      const data = await fetchPlanFeatures();
      const parsedPlans = data.map((plan) => {
        let parsedDescription = {};
        try {
          parsedDescription = JSON.parse(plan.description);
        } catch (e) {
          console.error("Error parsing plan description:", e);
        }

        const combinedFeatures = [
          ...(parsedDescription.includedFeature || []).map((feature) => ({
            description: feature,
            included: true,
          })),
          ...(parsedDescription.notIncludedFeatures || []).map((feature) => ({
            description: feature,
            included: false,
          })),
        ];

        return {
          ...plan,
          features: combinedFeatures,
          giftDescription: parsedDescription.giftDescription || null,
          giftImage: parsedDescription.giftImage || null,
        };
      });

      const sortedPlans = parsedPlans.sort((a, b) => a.id - b.id);

      setPlans(sortedPlans);
      setLoading(false);
    };

    getSubscriptions();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: !isMobile ? "row" : "column",
        flexWrap: !isMobile ? "wrap" : "nowrap",
        gap: !isMobile ? "20px" : "64px",
        justifyContent: "center",
        padding: "20px",
        paddingBottom: !isMobile ? "20px" : "64px",
      }}
    >
      {plans.map((plan) =>
        !isMobile ? (
          <OfferCell
            key={plan.id}
            originalPrice={plan.new_price}
            currentPrice={plan.new_price}
            planName={plan.name}
            durationElementInfo={translateSubscriptionType(plan.product_type)}
            planSubtext={plan.subtitle}
            features={plan.features}
            mostPopular={plan.product_type == "subscription_quarter"}
            giftImage={plan.giftImage}
            giftDescription={plan.giftDescription}
            onSelect={() => createNewSubscriptionsIntent(plan.id)}
          />
        ) : (
          <MobileOfferCell
            key={plan.id}
            originalPrice={plan.new_price}
            currentPrice={plan.new_price}
            planName={plan.name}
            durationElementInfo={translateSubscriptionType(plan.product_type)}
            planSubtext={plan.subtitle}
            features={plan.features}
            mostPopular={plan.product_type == "subscription_quarter"}
            giftImage={plan.giftImage}
            giftDescription={plan.giftDescription}
            onSelect={() => createNewSubscriptionsIntent(plan.id)}
          />
        ),
      )}
    </div>
  );
};

function translateSubscriptionType(input) {
  switch (input) {
    case "subscription_month":
      return "miesiąc";
    case "subscription_quarter":
      return "kwartał";
    case "subscription_year":
      return "rok";
    default:
      return "miesiąc";
  }
}

export default OfferContent;
