import {
  OAuthProvider,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "./firebase.config";
import { AuthProviders } from "./signUp";
import { getRequest, postRequest } from "../HttpService";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

export const tryToSignIn = async () => {
  try {
    const response = await getRequest("/api/user/login");
    return response;
  } catch (error) {
    throw error;
  }
};

export const signIn = async (email, password, type) => {
  switch (type) {
    case AuthProviders.STANDARD:
      await signInWithPassword(email, password);
      sendEventToGTM({
        eventName: "auth",
        action: "Login",
        label: "not_first_visited",
        value: "1",
      });
      break;
    case AuthProviders.GOOGLE:
      await signInWithGoogle();
      sendEventToGTM({
        eventName: "auth",
        action: "Login",
        label: "GOogle",
        value: "1",
      });
      break;
    case AuthProviders.APPLE:
      await signInWithApple();
      sendEventToGTM({
        eventName: "auth",
        action: "Login",
        label: "Apple",
        value: "1",
      });
      break;
    default:
      throw new Error("Invalid authentication provider");
  }

  let user;
  try {
    user = await tryToSignIn();
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.error("(404) trying to register");
      const date = new Date();
      const body = {
        email: email,
        created_time: date.toISOString(),
        last_activity: date.toISOString(),
        phone_number: 0,
        full_name: "Sarna",
        address: {
          city: "",
          street: "",
          zipCode: "",
        },
      };
      await postRequest("/api/user/register", body);
      const retryedUser = await tryToSignIn();
      sendEventToGTM({
        eventName: "user",
        action: "visited",
        label: "not_first_visited",
        value: `"${String(retryedUser.data.subscriptions)}"`,
      });
      user = retryedUser;
    } else {
      throw error;
    }
  }

  return user;
};

export const signInWithPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return user;
  } catch (error) {
    console.error("Error signing in:", error.message);
    throw error;
  }
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
  } catch (error) {
    console.error("Error", error.message);
  }
};

export const signInWithApple = async () => {
  const provider = new OAuthProvider("apple.com");

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
  } catch (error) {
    console.error("Error:", error.message);
  }
};
