import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../../services/authorization/signIn";
import TextField from "../../components/textFields/TextField";
import SecuredTextField from "../../components/textFields/PasswordTextField";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import VStack from "../../components/stacks/VStack";
import HStack from "../../components/stacks/HStack";
import Separator from "../../components/Separator";
import SocialButton from "../../components/buttons/social/SocialButton";
import "../../styles/Screens/LoginScreen/LoginScreen.css";
import { AuthProviders } from "../../services/authorization/signUp";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (e, type) => {
    e.preventDefault();
    setError('');
    setPasswordValidationError(false);

    try {
      let usr = await signIn(email, password, type);
      if (usr.data.subscriptions) {
        sendEventToGTM({
          eventName: "navigation",
          action: "Go to",
          label: "/dashboard",
          value: "1",
        });
        navigate('/dashboard');
      } else {
        sendEventToGTM({
          eventName: "navigation",
          action: "Go to",
          label: "/paywall",
          value: "1",
        });
        navigate('/paywall');
      }
    } catch {
      setPasswordValidationError(true);
    }
  };

  const onPasswordUpdate = (v) => {
    setPassword(v);
    setPasswordValidationError(false);
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="logo-container">
          <Link to={"/"}>
            <img src="/logo-default.svg" alt="Shape Factor Logo" className="logo" />
          </Link>
        </div>
        <div style={{ flexGrow: 0.7 }}></div>
        <div className="login-form-container">
          <div className="form-cntr">
            <div className="login-form mb-5">
              <h2 className="mb-2 display-lg">Witaj ponownie</h2>
              <p className="montserrat-sm mb-3">
                Dobrze widzieć cię z powrotem w Shape Factor
              </p>
            </div>
            <form className="login-form">
              <div className="mt-4">
                <TextField
                  label="Adres email"
                  placeholder="Wpisz adres email"
                  value={email}
                  onChange={setEmail}
                  required={true}
                />
                <SecuredTextField
                  label="Hasło"
                  placeholder="Wpisz hasło"
                  value={password}
                  onChange={onPasswordUpdate}
                  required={true}
                  validationError={passwordValidationError}
                  errorMessage="Sprawdz wprowadzone dane"
                />
                <div className="mb-4 login-not-remember-password">
                  <a
                    className="login-not-remember-password text-sm semibold"
                    href="/passwordReset"
                  >
                    Nie pamiętasz hasła?
                  </a>
                </div>
                <div className="mt-2">
                  <PrimaryButton
                    text="Zaloguj się"
                    onClick={(e) => handleLogin(e, AuthProviders.STANDARD)}
                  />
                  {error && <p className="error-message">{error}</p>}
                </div>
              </div>
            </form>
          </div>
          <div className="login-form mt-3">
            <HStack>
              <Separator />
            </HStack>
          </div>
          <div className="login-form mt-3">
            <VStack gap="16px">
              <SocialButton
                title={"Kontynuuj z Google"}
                imageSource={"./socials/google-logo.svg"}
                onClick={(e) => handleLogin(e, AuthProviders.GOOGLE)}
              />
              <SocialButton
                title={"Kontynuuj z Apple"}
                imageSource={"./socials/apple-logo.svg"}
                onClick={(e) => handleLogin(e, AuthProviders.APPLE)}
              />
            </VStack>
          </div>
        </div>
        <div className="mt-5">
          <p className="login-footer mb-3 text-sm">
            Nie masz jeszcze konta?{" "}
            <Link className="text-sm semibold" to={"/signup"}>
              Zarejestruj się
            </Link>
          </p>
          <p className="login-footer text-sm">
            Masz pytanie?{" "}
            <a className="text-sm semibold" href="mailto:pomoc@shapefactor.pl">
              pomoc@shapefactor.pl
            </a>
          </p>
        </div>
        <div style={{ flexGrow: 1 }}></div>
      </div>
      <div className="login-right">
        <img
          src="/login/login_page_default_img.svg"
          alt="Promo"
          className="promo-image"
        />
        <div className="app-promo-section">
          <h2 className="display-md">
            <span className="highlight-text italic-text">Każdy poziom.</span>{" "}
            Certyfikowany Trener osobisty w Twoim telefonie.
          </h2>
          <p className="text-md medium-italic mt-3 mb-5">
            Pobierz aplikację Shape Factor na swój telefon
          </p>
          <div className="app-store-buttons">
            <img
              src="/login/AppStore.svg"
              alt="Download on the App Store"
              className="app-badge"
            />
            <img
              src="/login/GooglePlay.svg"
              alt="Get it on Google Play"
              className="app-badge"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;