import React from "react";
import SecondaryButton from "../SecondaryButton";

const SocialButton = ({ title, imageSource, onClick, disabled = false }) => {
  return (
    <SecondaryButton
      text={
        <span className="button-content">
          <img src={imageSource} alt="Social logo" className="button-logo" />
          <p className="text-md semibold black-text">{title}</p>
        </span>
      }
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default SocialButton;
