import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUser } from "../../services/user/userService";
import "../../styles/Screens/ProfileScreen/ProfileScreen.css";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar";
import Footer from "../../components/Footer/Footer";
import BottomBar from "../../components/BottomBar/BottomBar";
import OrdersContent from "./Profile/OrdersContent";
import AccountDetails from "./Profile/AccountDetails";
import SubscriptionsContent from "./Profile/SubscriptionsContent";
import { fetchOrders } from "../../services/order/OrderService";
import { getUserSubscriptions } from "../../services/subscription/subscriptionService";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const ProfileScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("account");
  const [userData, setUserData] = useState(null);
  const [ordersData, setOrdersData] = useState(null);
  const [, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const renderContent = () => {
    switch (activeTab) {
      case "account":
        return <AccountDetails user={userData} onUserUpdate={fetchUserData} />;
      case "orders":
        return <OrdersContent />;
      case "subscriptions":
        return <SubscriptionsContent />;
      default:
        return <AccountDetails user={userData} />;
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    sendEventToGTM({
      eventName: "navigation",
      action: "Go to",
      label: "/profile",
      value: "1",
    });
    navigate(`/profile?=${tab}`);
  };

  useEffect(() => {
    const url = location.search;
    const tabMatch = url.match(/=\w+/);
    const tab = tabMatch ? tabMatch[0].slice(1) : "account";
    setActiveTab(tab);
  }, [location.search]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const data = await fetchUser();
        setUserData(data.data.user_details.user);
      } catch (error) {
        setLoading(false);
      }
    };
    const getOrders = async () => {
      try {
        const data = await fetchOrders();
        setOrdersData(data);
      } catch (error) {
        console.log(error);
      }
    };
    const getSubscriptions = async () => {
      try {
        const data = await getUserSubscriptions();
        setSubscriptionData(data);
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
    // getOrders();
    // getSubscriptions();
    setLoading(false);
  }, []);

  const fetchUserData = async () => {
    try {
      const data = await fetchUser();
      setUserData(data.data.user_details.user);
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-container">
      <div>
        <TopNavigationBar />
      </div>
      <div className="top-section">
        <h2 className="display-xl medium">Profil</h2>
      </div>
      <div className="profile-screen">
        <div className="tabs-menu">
          <ul className="text-s medium">
            <li
              className={activeTab === "account" ? "active" : ""}
              onClick={() => handleTabChange("account")}
            >
              <img src={"./icons/dropdown-user.svg"} alt="Profil" />
              Profil
            </li>
            <li
              className={activeTab === "orders" ? "active" : ""}
              onClick={() => handleTabChange("orders")}
            >
              <img src={"./icons/dropdown-order.svg"} alt="Zamówienia" />
              Historia zamówień
            </li>
            <li
              className={activeTab === "subscriptions" ? "active" : ""}
              onClick={() => handleTabChange("subscriptions")}
            >
              <img src={"./icons/dropdown-sub.svg"} alt="Subskrypcje" />
              Subskrypcje
            </li>
          </ul>
        </div>

        <div className="tab-content">{renderContent()}</div>
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <BottomBar />
      </div>
    </div>
  );
};

export default ProfileScreen;
