import React from "react";
import "../addToCardModal/AddToCardModal.css";
import { X } from "react-feather";
import PrimaryButton from "../buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const AddToCartModal = ({ product, onClose }) => {
  const navigate = useNavigate();

  const handleGoToBasket = async (e) => {
    e.preventDefault();
    sendEventToGTM({
      eventName: "navigation",
      action: "Go to",
      label: "/basket",
      value: "1",
    });

    navigate("/basket");
  };

  return (
    <div className="add-to-card-modal-overlay">
      <div className="add-to-card-modal-content">
        <button className="add-to-card-modal-close-btn" onClick={onClose}>
          <X size={24} />
        </button>
        <div className="add-to-card-modal-body">
          <div className="modal-check-icon">
            <img src="/icons/addToCardMainImg.svg" alt="Dodano do koszyka" />
          </div>
          <h3 className="text-lg semibold mb-3">Dodano do koszyka</h3>
          <p className="add-to-card-modal-product text-sm">
            Produkt -{" "}
            <span className="medium">
              {product.name} - {product.new_price}
            </span>{" "}
            zł został dodany do koszyka
          </p>

          <PrimaryButton
            text={"Przejdź do koszyka"}
            onClick={(e) => handleGoToBasket(e)}
          />
          <button
            className="mt-3 add-to-card-modal-secondary-btn text-s semibold"
            onClick={onClose}
          >
            Kontynuuj zakupy
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToCartModal;
