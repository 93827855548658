import React, { useState, useEffect } from "react";
import "../../styles/components/basket/BasketList.css";
import BasketItemCell from "./BasketCell";
import Separator from "../Separator";

const BasketList = ({ products, onAdd, onRemove }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (products && products.length > 0) {
      setItems(products);
    }
  }, [products]);

  return (
    <div className="basket-list-content">
      {items.length === 0 && (
        <div className="basket-list-empty-content">
          <h2>Nic tu nie ma, twój koszyk jest pusty</h2>
        </div>
      )}
      {items.map((product, index) => (
        <div key={`${product.id}-${index}`}>
          <BasketItemCell
            product={product}
            totalPrice={product.totalPrice}
            quantity={product.quantity}
            onAdd={onAdd}
            onRemove={onRemove}
          />
          <Separator />
        </div>
      ))}
    </div>
  );
};

export default BasketList;
