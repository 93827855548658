import React, { useState } from "react";

const TextWithToggle = ({
  text,
  maxLength = 100,
  maxLine = 3,
  whiteSpaceAlways = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const isTextLong = text.length > maxLength;

  return (
    <div>
      <p
        className="montserrat-sm medium"
        style={{
          overflow: isExpanded ? "visible" : "hidden",
          display: isExpanded ? "block" : "-webkit-box",
          WebkitLineClamp: isExpanded ? "unset" : maxLine,
          WebkitBoxOrient: "vertical",
          whiteSpace: isExpanded
            ? "pre-line"
            : whiteSpaceAlways
              ? "pre-line"
              : "",
        }}
      >
        {isExpanded || !isTextLong
          ? text
          : `${text.substring(0, maxLength)}...`}
      </p>

      {isTextLong && (
        <p
          onClick={toggleExpansion}
          className="montserrat-sm medium"
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {isExpanded ? "mniej" : "więcej"}
        </p>
      )}
    </div>
  );
};

export default TextWithToggle;
