import React, { useEffect, useState, useRef } from "react";
import "../../styles/Screens/WorkoutScreen/WorkoutScreen.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";

import { useLocation } from "react-router-dom";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar";
import { fetchWorkoutFormWorkout } from "../../services/training/WorkoutsService";
import {
  fetchProgram,
  fetchProgramById,
} from "../../services/training/ProgramService";
import { TabMenu } from "primereact/tabmenu";
import WorkoutScheduleCell from "../../components/workoutsCells/WorkoutScheduleCell";
import Separator from "../../components/Separator";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

const WorkoutScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const workoutId = searchParams.get("idx");

  const [training, setTraining] = useState();
  const [workout, setWorkout] = useState([]);
  const [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);

  const [activeIndex, setActiveIndex] = useState(1);

  const tabs = [
    {
      label: "Rozpiska",
      category: "schedule",
    },
    {
      label: "Ruchy",
      category: "moves",
    },
  ];

  const videoRef = useRef(null);

  useEffect(() => {
    const getWorkoutInformation = async () => {
      const fetchedData = await fetchWorkoutFormWorkout(workoutId);
      setWorkout(fetchedData);

      if (fetchedData.length > 0) {
        const fetchedProgram = await fetchProgramById(
          fetchedData[0].workoutReference,
        );
        const fetchedTraining = await fetchProgram(fetchedProgram.program.id);
        let sortedPrograms = fetchedData.sort((a, b) =>
          a.order > b.order ? 1 : -1,
        );

        setProgram(fetchedProgram);
        setTraining(fetchedTraining);

        if (fetchedProgram.training) {
          setActiveIndex(0);
        }

        setSelectedTask(fetchedData[0]);

        if (videoRef.current) {
          videoRef.current.src = fetchedData[0].video;
          videoRef.current.load();
        }
      }

      setLoading(false);
    };

    getWorkoutInformation();
  }, [workoutId]);

  const handleVideoChange = () => {
    sendEventToGTM({
      eventName: "Video",
      action: "Start",
      label: "video",
      value: "1",
    });
    if (videoRef.current) {
      videoRef.current.style.opacity = 0;
      setTimeout(() => {
        videoRef.current.src = selectedTask.video;
        videoRef.current.load();
        videoRef.current.style.opacity = 1;
      }, 300);
    }
  };

  useEffect(() => {
    handleVideoChange();
  }, [selectedTask]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!selectedTask || !program) {
    return <div>No tasks available.</div>;
  }

  const tabberDiv = (
    <div className="training-list-section">
      <h3 className="display-xs medium">{program.name}</h3>
      {program.training && (
        <div>
          <TabMenu
            model={tabs}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            className="workout-tabmenu"
          />
        </div>
      )}
      <div className="workouts-tabs-content">
        {activeIndex == 1 &&
          workout
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((task, index) => (
              <div
                key={index}
                className={`training-task ${
                  selectedTask.video === task.video ? "active" : ""
                }`}
                onClick={() => setSelectedTask(task)}
              >
                <img src={task.image} alt={task.name} className="task-image" />
                <div className="task-info">
                  <h4 className="text-md medium">{task.name}</h4>
                  <div className="task-duration">
                    {selectedTask.video === task.video && (
                      <img
                        src="./icons/play-icon.svg"
                        alt="icon"
                        className="play-icon"
                      />
                    )}
                    <h4 className="text-s medium">{task.duration}</h4>
                  </div>
                </div>
              </div>
            ))}
        {activeIndex == 0 &&
          program.training &&
          program.training.map((trng, index) => (
            <div>
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: trng }}
                style={{ marginBottom: "20px" }}
              />
              <Separator margin={"16px 0px 0px 16px"} />
            </div>
          ))}
      </div>
    </div>
  );

  const workoutDescription = (
    <div className="training-info">
      <div className="training-stats">
        <div className="stats-content-item">
          <img src="./icons/barbell.svg" alt="icon" className="element-icon" />
          <p className="montserrat-sm semibold">
            {program.equipment.join(", ")}
          </p>
        </div>
        <div className="stats-content-item">
          <img
            src="./icons/lightning.svg"
            alt="icon"
            className="element-icon"
          />
          <p className="montserrat-sm semibold">
            {program.difficulty || "Średniozaawansowany"}
          </p>
        </div>
        <div className="stats-content-item">
          <img src="./icons/clock.svg" alt="icon" className="element-icon" />
          <p className="montserrat-sm semibold">{program.duration} min</p>
        </div>
      </div>
      {program.description && program.description !== "-" && (
        <div className="video-desc-section">
          <h4 className="display-xs medium mb-4">O treningu</h4>

          <div
            key={"description"}
            dangerouslySetInnerHTML={{ __html: program.description }}
          />
        </div>
      )}
    </div>
  );

  return (
    <div>
      <TopNavigationBar />
      <div className="workout-screen-container">
        <div className="video-player-section">
          <video
            ref={videoRef}
            controls
            width="100%"
            controlsList="nodownload"
            muted
          />
          <div className="workout-web-description-container">
            {workoutDescription}
          </div>
        </div>

        {/* <div> */}
        {tabberDiv}
        {/* </div> */}

        <div className="workout-mobile-description-container">
          {workoutDescription}
        </div>
      </div>
    </div>
  );
};

export default WorkoutScreen;
