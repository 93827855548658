import React, { useState } from "react";
import "../../styles/components/exampleWorkoutProgram/ExampleWorkoutProgram.css";

const ExampleWorkoutProgram = () => {
  const workouts = [
    {
      id: 1,
      title: "Cardiomasakra®",
      description:
        "Autorski program treningowy mający na celu spalenie dużej ilości kalorii w trakcie jednej sesji treningowej.",
      bulletPoints: [
        "Wyrzeźbienie mięśni",
        "Wysmuklenie sylwetki",
        "Poprawę ogólnej sprawności naszego ciała",
      ],
      videoUrl:
        "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/videos%2Fhero%2FCardiomasakra.mp4?alt=media&token=64076e14-674c-45af-ba80-59bf9897c96b",
    },
    {
      id: 2,
      title: "Beginners",
      description: "Zobacz fragment treningu dla początkujących.",
      bulletPoints: [
        "Wyrzeźbienie mięśni",
        "Wysmuklenie sylwetki",
        "Poprawę ogólnej sprawności naszego ciała",
        "Wysmuklenie sylwetki",
        "Wysmuklenie sylwetki",
      ],
      videoUrl:
        "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/videos%2Fhero%2FGluteWorkout.mp4?alt=media&token=80733184-52e0-41d6-84e9-15a9b6cd72e0",
    },
    {
      id: 3,
      title: "Glute Workout's",
      description: "Zobacz fragment treningu na pośladki.",
      bulletPoints: ["Wysmuklenie sylwetki"],
      videoUrl:
        "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/videos%2Fhero%2FBeginners.mp4?alt=media&token=5afb51f2-7358-4b65-981a-aa8fb8c66294",
    },
  ];

  const [selectedWorkout, setSelectedWorkout] = useState(workouts[0]);

  const handleWorkoutClick = (workout) => {
    setSelectedWorkout(workout);
  };

  return (
    <div className="example-workout-program">
      <div className="workout-list">
        {workouts.map((workout) => (
          <div
            key={workout.id}
            className={`workout-item ${selectedWorkout.id === workout.id ? "active" : "inactive"}`}
            onClick={() => handleWorkoutClick(workout)}
          >
            <h3 className="display-xs medium">{workout.title}</h3>
            {selectedWorkout.id != workout.id && (
              <p className="watch-fragment text-md semibold">
                Zobacz fragment →
              </p>
            )}
            <div className={`workout-description`}>
              <p className="montserrat-sm medium">{workout.description}</p>
              <div className="mt-3">
                {workout.bulletPoints && (
                  <p className="montserrat-sm medium">
                    {workout.bulletPoints.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="workout-video">
        <video controls width="100%" src={selectedWorkout.videoUrl} />
      </div>
    </div>
  );
};

export default ExampleWorkoutProgram;
