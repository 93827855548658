import React, { createContext, useState, useContext, useEffect } from "react";
import { getBasket } from "../services/basket/basketService";
import { useAuth } from "../services/authorization/authContext";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();
  const [itemsInBasket, setItemsInBasket] = useState(0);
  const [loading, setLoading] = useState(true);
  const [basketData, setBasketData] = useState(null);

  const updateCartCount = (count) => {
    setItemsInBasket(count);
  };

  useEffect(() => {
    if (authLoading) return;

    const fetchBasketData = async () => {
      setLoading(true);
      try {
        const data = await getBasket(user);
        if (data && data.product_ids.length > 0) {
          const productIds = JSON.parse(data.product_ids);
          const totalQuantity = productIds.reduce(
            (total, product) => total + product.quantity,
            0
          );
          setItemsInBasket(totalQuantity);
          setBasketData(data);
        } else {
          setItemsInBasket(0);
          setBasketData(null);
        }
      } catch (error) {
        console.error("Error fetching basket data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBasketData();
  }, [user, authLoading]);

  return (
    <CartContext.Provider
      value={{ itemsInBasket, updateCartCount, basketData, loading }}
    >
      {children}
    </CartContext.Provider>
  );
};
