import { getRequest } from "../HttpService";

export const checkUserSubscription = async () => {
  try {
    const response = await getRequest("/api/user/login");
    return response.data.subscriptions;
  } catch (error) {
    return false;
  }
};
