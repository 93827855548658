import React, { useEffect } from "react";
import TopNavigationBar from "../../../components/NavigationBar/TopNavigationBar";
import AppPromoSection from "../../../components/promo/AppPromoSection";
import Footer from "../../../components/Footer/Footer";
import BottomBar from "../../../components/BottomBar/BottomBar";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const InfoElement = ({ success }) => {
  const iconStyle = {
    color: success ? "green" : "red",
    fontSize: "100px",
    marginBottom: "16px",
  };

  const textClassName = `text-lg semibold ${
    success ? "success-text" : "failure-text"
  }`;

  return (
    <div style={{ textAlign: "center" }}>
      {success ? (
        <FaCheckCircle style={iconStyle} />
      ) : (
        <FaTimesCircle style={iconStyle} />
      )}
      <div className={textClassName}>
        {success
          ? "Płatność powiodła się, sprawdź adress email"
          : "Płatność zakonczona niepowodzeniem, powtórz operacją bądź skontaktuj się z supportem"}
      </div>
    </div>
  );
};

// export default InfoElement;

const PaymentStatusScreen = ({ success }) => {
  return (
    <div>
      <TopNavigationBar />
      <div className="mt-5 mb-5">
        {/* {success === true ? "Success" : "Failure"} */}
        <InfoElement success={success} />
      </div>
      <div>
        <AppPromoSection />
      </div>
      <div>
        <Footer />
      </div>
      <BottomBar />
    </div>
  );
};

export default PaymentStatusScreen;
