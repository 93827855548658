import React from "react";
import "../../styles/components/basket/SummaryCard.css";
import PrimaryButton from "../buttons/PrimaryButton";
import { createPayment } from "../../services/payment/paymentService";
import Separator from "../Separator";
import { useAuth } from "../../services/authorization/authContext";

const SummaryCard = ({ total, shipping }) => {
  const { user, ldng } = useAuth();

  const handleCreatePayment = async (e) => {
    e.preventDefault();

    try {
      const response = await createPayment(user);
      window.open(response, "_self");
    } catch (error) {
      console.error("Błąd podczas tworzenia płatności: ", error);
    }
  };

  return (
    <div className="summary-card">
      <h3 className="summary-title display-sm medium blue-text">
        Podsumowanie
      </h3>
      <div className="summary-details">
        <p className="text-md medium blue-text">Wartość koszyka</p>
        <p className="text-md medium blue-text">{total} zł</p>
      </div>
      <div className="summary-details">
        <p className="text-md medium blue-text">Dostawa</p>
        <p className="text-md medium blue-text">od {shipping} zł</p>
      </div>
      <Separator />
      <div className="summary-details mt-3">
        <p className="text-md medium blue-text">Razem (z VAT):</p>
        <p className="text-lg semibold blue-text">{total} zł</p>
      </div>
      <div className="mt-4">
        <PrimaryButton
          text="Przejdź do płatności"
          onClick={handleCreatePayment}
        />
      </div>
      <p className="mt-4 text-xs medium">Metody płatności</p>
      <div className="payment-methods mt-2">
        <img src="/summary/payment-options.svg" alt="Payment" />
      </div>
      <p className="mt-4 text-xs medium">Metody dostawy</p>
      <div className="delivery-methods mt-2">
        <img src="/summary/delivery-options.svg" alt="delivery" />
      </div>
    </div>
  );
};

export default SummaryCard;
