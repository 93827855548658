import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/Screens/OrderScreen/OrderView.css";
import { fetchOrderById } from "../../services/order/OrderService";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { fetchProducts } from "../../services/shop/productService";
import { stringAsObject } from "../../helpers/parser";

const OrderDetailsView = () => {
  const orderDetails = {
    orderId: "834952344098",
    orderDate: "20 styczeń 2024",
    products: [
      {
        name: "Długa guma",
        description:
          "Długa guma treningowa marki ShapeFactor wykonana z certyfikowanego materiału.",
        price: "59,99 zł",
        quantity: 1,
        image: "path-to-your-image.jpg",
      },
      {
        name: "Krótka guma",
        description: "Krótka guma treningowa ShapeFactor.",
        price: "39,99 zł",
        quantity: 2,
        image: "path-to-your-image-2.jpg",
      },
    ],
    delivery: {
      method: "Inpost paczkomaty",
      time: "1-3 dni robocze",
      cost: "9,99 zł",
    },
    customer: {
      name: "Karolina Nowak",
      address: "Piłsudzkiego 12/78, 01-123 Warszawa",
      phone: "+48 692 603 128",
    },
    payment: {
      method: "Stripe",
      card: "**** 9064",
      expiryDate: "01/29",
    },
    summary: {
      orderValue: "59,99 zł",
      deliveryCost: "9,99 zł",
      total: "69,98 zł",
    },
  };

  const location = useLocation();
  const [orderId, setOrderId] = useState(null);
  const [order, setOrders] = useState([]);
  const [orderFormattedDate, setDate] = useState("-");
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setOrderId(id);

    const getOrder = async () => {
      try {
        if (!id) return;
        setLoading(true);
        const data = await fetchOrderById(orderId);
        const fetchedProducts = await fetchProducts();
        const productIds = JSON.parse(data.data.basket.product_ids);

        const combinedProducts = productIds.map((item) => {
          const product = fetchedProducts.find((p) => p.id == item.product_id);
          const total = (product.new_price * item.quantity).toFixed(2);

          return {
            ...product,
            quantity: item.quantity,
            total,
          };
        });

        setOrderedProducts(combinedProducts);
        setOrders(data.data || []);

        const dateObject = new Date(order.order.created_time);

        const formattedDate = format(dateObject, "dd/MM/yyyy", { locale: pl });
        setDate(formattedDate || "");

        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    getOrder();
    console.log(order);
  }, [orderId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="order-view">
      <div className="order-header">
        <h2>Zamówienie: {orderId}</h2>
        <p className="text-xs medium">Data zamówienia: {orderFormattedDate}</p>
      </div>

      <div className="mt-5"></div>
      <table className="order-table">
        <thead>
          <tr>
            <th className="text-xs medium">Produkt</th>
            <th className="text-xs medium">Cena</th>
            <th className="text-xs medium">Ilość</th>
            <th className="text-xs medium">Suma</th>
          </tr>
        </thead>
        <tbody>
          {orderedProducts.map((product, index) => (
            <tr key={index}>
              <td>
                <div className="product-info">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="ordered-product-image"
                  />
                  <div className="product-details">
                    <h4 className="text-lg semibold">{product.name}</h4>
                    <p className="montserrat-xs medium">
                      {stringAsObject(product.description).description}
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-x semibold orderd-max-width">
                {product.new_price} zł
              </td>
              <td className="text-x semibold">{product.quantity}</td>
              <td className="text-x semibold orderd-max-width">
                {product.total} zł
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="order-summary mt-5">
        <div className="delivery-info">
          <h4 className="text-s semibold">Sposób dostawy:</h4>
          <p className="montserrat-xs medium">
            {orderDetails.delivery?.method}
          </p>
          <p className="montserrat-xs medium">{orderDetails.delivery?.time}</p>
          <p className="montserrat-xs medium">{"0 zł"}</p>
        </div>
        {/* <div className="customer-info">
                    <h4 className='text-s semibold'>Dane zamówienia:</h4>
                    <p className='montserrat-xs medium'>{orderDetails.customer.name}</p>
                    <p className='montserrat-xs medium'>{orderDetails.customer.address}</p>
                    <p className='montserrat-xs medium'>{orderDetails.customer.phone}</p>
                </div> */}
        {/* <div className="payment-info">
                    <h4 className='text-s semibold'>Metoda płatności:</h4>
                    <p className='montserrat-xs medium'>Stripe</p>
                    <p className='montserrat-xs medium'>Płatność kartą</p>
                </div> */}
      </div>

      <div className="order-total">
        <h3 className="text-lg semibold">Podsumowanie zamówienia</h3>
        <div className="summary-row">
          <span className="text-s medium">Wartość zamówienia</span>
          <span className="text-md semibold">
            {order.basket?.total_amount.toFixed(2) || "0"} zł
          </span>
        </div>
        <div className="summary-row">
          <span className="text-s medium">Koszt dostawy</span>
          <span className="text-md semibold">{"0 zł"}</span>
        </div>
        <div className="summary-row total">
          <span className="text-s medium">Razem</span>
          <span className="text-lg semibold">
            {order.basket?.total_amount.toFixed(2) || "0"} zł
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsView;
