import React from "react";
import "../../styles/components/footer/Footer.css";

const Footer = () => {
  const handleMailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <h2 className="mb-4 display-md medium">
            Shape Factor to nie postęp.
            <br />
            <span className="highlight display-md medium-italic">
              To przełom.
            </span>
          </h2>
          <div className="mb-4">
            <p className="text-xs medium">SHAPEFACTOR</p>
            <p className="text-xs medium">
              Spółka z Ograniczoną Odpowiedzialnością,
            </p>
            <p className="text-xs medium">Warszawa Ksawerów 3 , 02-656</p>
            <p className="text-xs medium">NIP: 5213973031, KRS: 0000977123</p>
          </div>
          <p className="text-xs medium">
            Masz pytania?{" "}
            <a href="mailto:pomoc@shapefactor.pl">pomoc@shapefactor.pl</a>
          </p>
        </div>

        <div />

                <div className="footer-center">
                    <p className="mb-4 text-xs-medium">Shape Factor</p>
                    <ul>
                        {/* <li><p className="footer-center-colored text-x-medium" href="#">Terminy dostawy</p></li> */}
                        {/* <li><p className="footer-center-colored text-x-medium" href="#">Koszty dostawy</p></li> */}
                        <li>
                            <p
                                className="footer-center-colored text-x-medium"
                                onClick={() => handleMailClick('pomoc@shapefactor.pl')}
                                style={{ cursor: 'pointer' }}
                            >
                                Pomoc
                            </p>
                        </li>
                        <li>
                            <p
                                className="footer-center-colored text-x-medium"
                                onClick={() => window.open(`${process.env.PUBLIC_URL}/terms.pdf`, '_blank')}
                                style={{ cursor: 'pointer' }}
                            >
                                Regulamin sklepu
                            </p>
                        </li>
                        <li>
                            <p
                                className="footer-center-colored text-x-medium"
                                onClick={() => handleMailClick('pomoc@shapefactor.pl')}
                                style={{ cursor: 'pointer' }}
                            >
                                Kontakt
                            </p>
                        </li>
                    </ul>
                </div>

        <div className="footer-right">
          <div className="footer-social mb-3">
            <p className="mb-2 text-xs-medium">Dołącz do nas</p>
            <a href="#" aria-label="Instagram">
              <img src="/socials/instagram.svg" alt="Instagram" />
            </a>
            <a href="#" aria-label="Facebook">
              <img src="/socials/facebook.svg" alt="Facebook" />
            </a>
          </div>
          <div className="footer-partners mb-3">
            <p className="mb-2 text-xs-medium">Partnerzy</p>
            <img src="/partners_logo.svg" alt="Partner 1" />
          </div>
          <div className="footer-payment">
            <p className="mb-2 text-xs-medium">Metody płatności</p>
            <div className="payment-icons">
              <img src="/payments/blik.svg" alt="Blik" />
              <img src="/payments/visa.svg" alt="Visa" />
              <img src="/payments/mastercard.svg" alt="Mastercard" />
              <img src="/payments/gpay.svg" alt="Google Pay" />
              <img src="/payments/apay.svg" alt="Apple Pay" />
              <img src="/payments/stripe.svg" alt="Stripe" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
