import React from "react";
import Modal from "react-modal";
import "../../styles/Screens/ModalComponent.css";
import { X } from "react-feather";
import PrimaryButton from "../buttons/PrimaryButton";

const ModalContent = ({ isOpen, onClose, title, description, buttonText }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="default-modal-content"
      overlayClassName="default-modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h2 className="display-lg semibold">{title}</h2>
        <button onClick={handleClose} className="close-button">
          <X size={24} color="#B46EF9" />
        </button>
      </div>

      <div className="modal-body">
        <p className="text-lg blue-text mb-5">{description}</p>
      </div>

      <div className="mt-2">
        <PrimaryButton text={buttonText} onClick={handleClose} />
      </div>
    </Modal>
  );
};

export default ModalContent;
