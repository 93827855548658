import React, { useState, useEffect } from "react";
import "../../styles/Screens/BasketScreen/BasketScreen.css";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar";
import Footer from "../../components/Footer/Footer";
import BottomBar from "../../components/BottomBar/BottomBar";
import {
  addProduct,
  getBasket,
  removeProduct,
} from "../../services/basket/basketService";
import { fetchProducts } from "../../services/shop/productService";
import BasketList from "../../components/basket/BasketList";
import SummaryCard from "../../components/basket/SummaryCard";
import { useCart } from "../../providers/CartContext";
import { useAuth } from "../../services/authorization/authContext";

const BasketScreen = () => {
  const { user, ldng } = useAuth();
  const { updateCartCount, itemsInBasket } = useCart();
  const [basket, setBasket] = useState([]);
  const [products, setProducts] = useState([]);
  const [combinedModel, setCombinedModel] = useState({
    products: [],
    total: 0,
    uid: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshBasket();
  }, []);

  const refreshBasket = async () => {
    try {
        const data = await getBasket(user);
        const totalAmount = data.total_amount;
        const uid = data.uid;

        if (data.product_ids.length == 0) {
            setLoading(false);
            return
        }

        const productIds = data.product_ids ? JSON.parse(data.product_ids) : [];

        setBasket(JSON.parse(data.product_ids));

        const productData = await fetchProducts();
        setProducts(productData);

        setCombinedModel((prev) => ({
            ...prev,
            total: totalAmount.toFixed(2),
            uid: uid,
            products: createModel(JSON.parse(data.product_ids), productData)
        }));

        setLoading(false);
    } catch (error) {
        console.log(error);
    }
};

const createModel = (basketItems, products) => {
    return basketItems.map((basketItem) => {
        const productDetails = products.find(
            (product) => product.id === parseInt(basketItem.product_id)
        );

        if (productDetails) {
            return {
                ...productDetails,
                quantity: basketItem.quantity,
                totalPrice: (basketItem.quantity * productDetails.new_price).toFixed(2)
            };
        }
        return null;
    }).filter(item => item !== null);
};

  const handleQuantityChange = async (productId, newQuantity) => {
    try {
      await addProduct(productId, user);
      updateCartCount(itemsInBasket + 1);
      refreshBasket();
    } catch (error) {
      console.error("Error updating quantity: ", error);
    }
  };

  const handleRemoveProduct = async (productId) => {
    try {
      console.log("trying to remove");
      await removeProduct(productId, user);
      updateCartCount(itemsInBasket - 1);
      refreshBasket();
    } catch (error) {
      console.error("Error removing product: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="basket-page">
      <TopNavigationBar />
      <div className="basket-content-container">
        <div className="basket-header-container">
          <p className="text-md semibold primary-text">Dokończ zakupy</p>
          <h2 className="display-xl medium">
            Koszyk
            <snap className="display-xl medium-italic primary-text"></snap>
          </h2>
        </div>

        {combinedModel.products.length > 0 && (
          <div className="basket-main-container">
            <div className="basket-list-container">
              <BasketList
                products={combinedModel.products}
                onAdd={handleQuantityChange}
                onRemove={handleRemoveProduct}
              />
            </div>
            <div className="basket-summary-container">
              <SummaryCard total={combinedModel.total} shipping={9.99} />
            </div>
          </div>
        )}
        {combinedModel.products.length < 1 && (
          <div className="basket-list-empty-content display-xl semibold blue-text">
            Koszyk jest pusty, przejdź do sklepu by dodać produkt
          </div>
        )}
        <div className="flex-grow"></div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
      <BottomBar />
    </div>
  );
};

export default BasketScreen;
