import React from "react";
import "../../../styles/components/training/TrainingCell.css";
import { Clock, Activity } from "react-feather";

const TrainingCell = ({ title, time, level, equipment, imageUrl, onClick }) => {
  return (
    <div className="training-cell" onClick={onClick}>
      <img src={imageUrl} alt={title} />

      <div className="training-cell-content">
        <div className="training-cell-base">
          <h3 className="display-xs medium">{title}</h3>
          <div className="time-background">
            <div className="training-cell-subtitle">
              <img src="./icons/clock.svg" alt="icon" className="icon" />
              <span className="text-s medium">{time} min</span>
            </div>
          </div>
        </div>

        <div className="training-details">
          <snap>
            <div className="text-xs medium training-details-container">
              <img src="./icons/clock.svg" alt="icon" className="icon" />
              Czas trwania:
            </div>

            <h1 className="text-md medium">{time} min</h1>
          </snap>
          <snap>
            <div className="text-xs medium training-details-container">
              <img src="./icons/lightning.svg" alt="icon" className="icon" />
              Poziom:
            </div>
            <h1 className="text-md medium">{level}</h1>
          </snap>
          <snap>
            <div className="text-xs medium training-details-container">
              <img src="./icons/barbell.svg" alt="icon" className="icon" />
              Sprzęt:
            </div>
            <h1 className="text-md medium">{equipment}</h1>
          </snap>
        </div>
      </div>
    </div>
  );
};

export default TrainingCell;
