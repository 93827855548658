import { postRequest } from "../HttpService";
import { getGuestBasketUID } from "../basket/basketService";

export const createPayment = async (user) => {
  try {
    if (user) {
      const response = await postRequest("/api/payment");
      return response;
    } else {
      let uid = getGuestBasketUID();
      let body = { uid: uid };
      console.log(body);
      const response = await postRequest("/api/guest/payment", body);
      return response;
    }
  } catch (error) {
    throw error;
  }
};
