import React from "react";

const blogData = {
  1: {
    id: 1,
    tag: "Motywacja",
    mainTitle: "Poznaj mnie i moją historię",
    description:
      "Od zawsze byłam aktywnym dzieckiem. Prawdopodobnie wynika to z faktu, że mój tata całe  życie pracował jako wuefista i zaszczepił we mnie miłość do sportu...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fstory%2Fstory_main_img.png?alt=media&token=5ed215b8-3114-4185-a66b-d7869f6670f4",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">
          Od zawsze byłam aktywnym dzieckiem. Prawdopodobnie wynika to z faktu,
          że mój tata całe życie pracował jako wuefista i zaszczepił we mnie
          miłość do sportu. Już w II. klasie szkoły podstawowej rodzice zapisali
          mnie na zajęcia do grupy tanecznej.
        </h1>
        <div style={{ height: 56 }}></div>
        <p className="montserrat-md grey-700">
          <strong>Od zawsze byłam aktywnym dzieckiem.</strong> Prawdopodobnie
          wynika to z faktu, że mój tata całe życie pracował jako wuefista i
          zaszczepił we mnie miłość do sportu. Już w II. klasie szkoły
          podstawowej rodzice zapisali mnie na zajęcia do grupy tanecznej. Nie
          było to jednak tańczenie kujawiaka, a raczej treningi, które w sumie,
          jak teraz się nad tym zastanawiam - trochę przypominały{" "}
          <strong>CARDIOMASAKRĘ</strong>. Przez 2,5h zajęć cały czas byliśmy w
          ruchu, zakaz siadania, przerwa tylko za zgodą trenera, za każde
          spóźnienie na trening - skakanie karnych „żabek”, a na koniec treningu
          pół godziny tzw. siłówki, czyli kilkaset powtórzeń brzuszków i innych
          podobnych ćwiczeń.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          W ferie zimowe - stacjonarny obóz sportowy, podczas którego
          trenowaliśmy przez 4 godziny dziennie, szlifowaliśmy choreografię, po
          to żeby później pojechać na zawody. Miałam o tyle trudniej, że przez
          mój wzrost (zawsze byłam wyższa co najmniej o głowę od rówieśników),
          trafiłam do grupy ze starszymi o 2-6 lat dziewczynami i musiałam im
          dorównać. W tym wieku, jak możecie się domyślać, nie było to
          najłatwiejszym zadaniem. Po kilku latach{" "}
          <strong>zrezygnowałam z treningów,</strong> bo bardziej stawiałam na
          naukę, a ciężko było pogodzić jedno z drugim. Przepisałam się więc do
          innej grupy, gdzie zajęcia były już o wiele lżejsze, ale cały czas
          sprawiały, że odczuwałam satysfakcję. Niestety po pewnym czasie, także
          zaczęło to kolidować ze szkołą, więc totalnie odpuściłam temat i
          skupiłam się na zdaniu dobrze testów gimnazjalnych.
        </p>
        <div style={{ height: 24 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fstory%2Fstory_img_1.png?alt=media&token=6b42170f-9586-4c4a-a600-5d244c9b77c2"
          style={{ maxWidth: "100%" }}
        ></img>
        <div style={{ height: 36 }}></div>
        <p className="montserrat-md grey-700">
          W międzyczasie, trenowałam także <strong>lekkoatletykę</strong> - skok
          w dal, jeździłam na zawody sportowe, ale przez to, że nie chciałam
          zaniedbywać szkoły, nie mogłam poświęcić się temu w 100%,{" "}
          <strong>
            a jeśli chce się być w czymś najlepszym, również w 100% trzeba się
            temu poświęcić.
          </strong>{" "}
          W gimnazjum moja aktywność fizyczna była znikoma i ograniczała się do
          lekcji w-f. Zawsze miałam smykałkę do sportu, więc nigdy tych zajęć
          nie opuszczałam, ale nie robiłam nic poza tym.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Gdy poszłam do liceum i na ważeniu u pielęgniarki okazało się, że{" "}
          <strong>wybiło 72kg</strong> na liczniku, trochę mnie to zaniepokoiło.
          Wtedy chyba po raz pierwszy w ogóle zaczęłam zastanawiać się nad swoim
          wyglądem, a konkretniej zaczęłam zauważać, że spodnie są takie jakby
          trochę przyciasne, guzik ciężko się zapina, ale nic z tym nie zrobiłam
          i dalej zajadałam się codziennie drożdżówkami z serem (kocham) na
          drugie śniadanie. Przełom nastąpił mniej więcej w drugiej połowie I.
          klasy liceum, kiedy to faktycznie dotarło do mnie, że drożdżówki
          zaczęły dawać się we znaki.{" "}
          <strong>
            Nie miałam wtedy absolutnie zielonego pojęcia na temat diety.
          </strong>{" "}
          Zobaczyłam gdzieś w TV reklamę płatków zbożowych fitness, gdzie hasłem
          reklamowym było:{" "}
          <strong>„Schudnij w 14 dni jedząc płatki fitness!”</strong> Nie
          czekałam długo, ruszyłam do sklepu i zaczęłam działać. Pamiętam jak
          dziś swój pierwszy dzień w szkole bez drożdżówki na drugie śniadanie
          (zamiast tego przygotowałam sobie marchewki i jabłko). Po powrocie do
          domu zjadłam pierogi, ale już nie 10, a 3. Na kolację oczywiście owe
          płatki z jogurtem. Po pierwszych dwóch tygodniach zaczęłam zauważać,
          że spodnie, które były już za ciasne, nagle pasowały idealnie.
          Zmotywowana efektami brnęłam w to dalej. Po około 2-3 miesiącach
          okazało się, że zrzuciłam około 13kg.
        </p>
        <div style={{ height: 42 }}></div>
        <h2 className="display-xs medium blue-text">Początki modelingu</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Koleżanka z klasy podesłała mi informację, że w Warszawie organizowany
          jest konkurs, gdzie główną wygraną był kontrakt z jedną z lepszych
          warszawskich agencji. Stwierdziłam, że spróbuję swoich sił, bo moja
          sylwetka po kilku miesiącach odchudzania, zaczęła powoli przypominać
          tę z wybiegów. Dalej oczywiście leciałam na płatkach. Oprócz udziału w
          konkursie, porozsyłałam swoje zdjęcia (tzw. polaroidy, czyli zdjęcia
          saute, bez makijażu w stroju kąpielowym, aby dobrze było widać
          sylwetkę i proporcje - takie są wymogi) u innych agencji
          modelingowych.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Odezwało się dużo agencji, pojechałam więc z rodzicami do Warszawy na
          spotkania. Z konkursu zrezygnowałam, bo dostałam od razu kontrakt w
          lepszej agencji. Wszystko działo się pod koniec pierwszej klasy
          liceum. Miałam wtedy szansę na wyjazd za granicę, ale był akurat
          koniec roku szkolnego, więc odpuściłam tę opcję.. i w sumie każdą
          kolejną również. Na co dzień mieszkałam w Toruniu, nie mogłam sobie
          pozwolić na opuszczanie szkoły po to aby pojawić się na castingu w
          Warszawie. Nauka zawsze była dla mnie i dla moich rodziców
          priorytetem. Może trochę zaprzepaściłam swoją szansę, bo niewiadomo co
          by się stało, gdybym faktycznie wyleciała od razu po tygodniu od
          podpisania umowy na swój pierwszy kontrakt, ale najwidoczniej tak
          musiało być. Niejako dzięki temu jestem teraz tu, gdzie jestem i piszę
          Wam to z nadzieją, że Was to choć trochę zaciekawi.
        </p>
        <div style={{ height: 24 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fstory%2Fstory_img_2.png?alt=media&token=14bc15b9-5a8f-4999-b0b4-f66a645036fe"
          style={{ maxWidth: "100%" }}
        ></img>
        <div style={{ height: 36 }}></div>
        <p className="montserrat-md grey-700">
          Po powrocie z Chin stwierdziłam, że to nie dla mnie i byłam
          przekonana, że nie chcę iść w tę stronę. Dotarło też do mnie, że nie
          da się przez dłuższy czas jeść 1000-1200 kalorii, bo to po prostu
          wykańcza zarówno organizm jak i psychikę.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Postanowiłam, że zacznę trenować aby dalej pracować nad swoim ciałem.
          Wtedy właśnie zapisałam się na Crossfit i założyłam swoją stronę na
          Facebooku - My daily routine, która miała służyć jako osobisty
          dziennik i motywacja dla siebie i innych. To zdecydowanie było
          przełomowym wydarzeniem. Po pierwszym treningu miałam takie zakwasy,
          że nie mogłam wstać z łóżka, ale już nie mogłam się doczekać kolejnego
          treningu. Zaczęłam trenować regularnie, na początku 3 x w tygodniu,
          później nawet częściej. Treningi były dość wymagające, więc siłą
          rzeczy zaczęłam więcej jeść. Mój znajomy trener rozpisał mi wtedy
          dietę, prawie 2500 kalorii i zobaczyłam, że wcale nie wyglądam źle,
          jedząc prawie dwa razy tyle co wcześniej.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Przestałam już zwracać taką uwagę na wymiary, bo to nie było moim
          priorytetem. Zakochałam się na nowo w sporcie, w aktywności fizycznej
          ogólnie, robiłam to czysto z przyjemności, sprawdzałam swoje
          możliwości, starałam się przekraczać granice. Z czasem jednak
          zauważyłam, że moja sylwetka mocno się zmieniła, nie do końca w taki
          sposób w jaki bym chciała.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Crossfit to specyficzna dyscyplina, dużo ciężarów, wysoka
          intensywność. I o ile uważam, że jest to idealna forma treningu,
          zarówno jeśli chodzi o ogólną sprawność, siłę, wytrzymałość i
          kształtowanie sylwetki w przypadku mężczyzn, to dla kobiet ten ostatni
          aspekt już nie bardzo zdaje sprawdzian. Przestałam trenować w momencie
          gdy zauważyłam, że moje uda ledwo mieszczą się w spodnie, a koszula
          jest ciasna w przedramionach.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Sylwetki kobiet trenujących Crossfit są zdecydowanie bardziej masywne
          i mocniej umięśnione i wygląd, proporcje mojego ciała zaczęły zmierzać
          właśnie w tym kierunku i nie czułam się z tym dobrze. Zrezygnowałam z
          typowych zajęć Crossfit. Zaczęłam sama kombinować z treningami,
          zdecydowanie mniej ciężarów, więcej biegania i ćwiczeń z obciążeniem
          swojego ciała.
        </p>
        <div style={{ height: 24 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fstory%2Fstory_img_3.png?alt=media&token=23a3cac3-f04b-4065-baa1-c80392c6d980"
          style={{ maxWidth: "100%" }}
        ></img>
        <div style={{ height: 36 }}></div>
        <h2 className="display-xs medium blue-text">Nowa ścieżka</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Później trenowałam typowym treningiem kulturystycznym. Każdego dnia
          kilka ćwiczeń na każdą partię osobno, ale trenując w ten sposób nie
          odczuwałam aż takiej satysfakcji.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Oczywiście pojawiały się wzloty i upadki, ale cały czas starałam się
          nie zaniedbywać aktywności fizycznej pomimo dużej ilości obowiązków -
          stała praca i studia magisterskie. W dalszym ciągu interesowałam się
          wszystkim co było związane ze sportem, aktywnością fizyczną ogólnie,
          treningami, odżywianiem. W każdej wolnej chwili szukałam informacji na
          własną rękę, cały czas zgłębiałam swoją wiedzę w tym zakresie.
          Wiedziałam już jak funkcjonuje moje ciało i czego mogę się po nim
          spodziewać.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Po paru latach stwierdziłam, że to wszystko towarzyszy mi już tyle
          czasu, tyle diet i form treningu przetestowałam na sobie, popełniłam
          wiele błędów, popadałam w zaburzenia odżywiania i w końcu sama
          zaczęłam sobie z tym radzić, że chcę związać z tym swoją przyszłość na
          poważnie.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Ukończyłam kurs trenerski, ale zanim zaczęłam pracować minęło jeszcze
          trochę czasu. Dalej szłam w tym kierunku starając się nieustannie
          zwiększać swoją wiedzę na licznych szkoleniach i tak jak do tej pory -
          szukając samodzielnie informacji i testując na sobie. to, czego się
          dowiadywałam. W pewnym momencie pracowałam na dwa etaty - w ciągu dnia
          praca za biurkiem, wieczorami treningi personalne. W końcu
          zrezygnowałam ze stałej pracy na rzecz prowadzenia treningów. To była
          najlepsza decyzja w moim życiu, bo po raz pierwszy poczułam, że
          naprawdę robię to, co kocham. Cieszę się, że przeszłam taką drogę
          (uwierzcie, starałam się to wszystko bardzo streścić) i jestem w tym
          miejscu tu i teraz pisząc ten artykuł. Cała moja działalność w
          Internecie opiera się na tym aby pomagać innym - Wam i przestrzec,
          uchronić przed błędami, które ja sama popełniłam, ale nie miałam
          takiej osoby, która naklepałaby mi wtedy do głowy i postawiła do
          pionu.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Zanim doszłam do formy, która mnie satysfakcjonuje, wypróbowałam wiele
          rodzajów treningu. Teraz już wiem jak funkcjonuje moje ciało i
          znalazłam ten tzw. „złoty środek”, którym chcę się z Wami dzielić.
          Przez ostatnie lata robiłam to za pośrednictwem moich e-booków
          treningowych, a teraz dzięki tej aplikacji.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md grey-700">
          Myślę, że w mojej historii każda z Was odnajdzie trochę siebie - czy
          jeśli chodzi o przykrą przygodę z zaburzeniami odżywiania, czy wzloty
          i upadki jeśli chodzi o motywację. Mam nadzieję, że pomogę Wam w
          dążeniu do osiągnięcia dobrej formy zarówno fizycznej jak i
          psychicznej, bo o tym aspekcie również nie powinniśmy zapomnieć.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fstory%2Fstory_img_4.png?alt=media&token=26d9c498-84a4-4114-ba00-570bd7f2cd06"
          style={{ maxWidth: "100%" }}
        ></img>
        <div style={{ height: 16 }}></div>
      </div>
    ),
  },
  2: {
    id: 2,
    tag: "Porady",
    mainTitle: "Wzmocnij swoje ciało i popraw proporcje sylwetki z FBW!",
    description:
      "o nic innego jak sesja treningowa, podczas której trenujemy niemal każdą grupę mięśniową naszego ciała. Według mnie, taki plan jest idealny ...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Ffbw%2Ffbw_main_img.png?alt=media&token=cc3deb21-a6f0-4c66-a6c0-1ee429d6bc4a",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "24 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">
          System treningu full body workout jest bardzo wszechstronny, co
          przygotowuje też nasze ciało i mięśnie do innego rodzaju wysiłku.
          Podczas wykonywania poszczególnych ćwiczeń, bardzo istotna jest
          poprawna technika, odpowiednie prowadzenie ruchu, spinanie mięśni i
          kontrolowanie oddechu.
        </h1>
        <div style={{ height: 56 }}></div>
        <p className="montserrat-md medium grey-700 grey-700">
          To nic innego jak sesja treningowa, podczas której trenujemy niemal{" "}
          <strong>każdą grupę mięśniową naszego ciała.</strong> Według mnie,
          taki plan jest idealny nie tylko dla{" "}
          <strong>
            początkujących osób, ale także dla tych bardziej zaawansowanych.
          </strong>{" "}
          Sama trenowałam już na wiele sposobów, przykładowo dzieląc dni
          treningowe na poszczególne partie ciała. Natomiast w dłuższej
          perspektywie czasu uważam, że trenując systemem{" "}
          <strong>
            full body workout, jesteśmy w stanie osiągnąć lepsze efekty.
          </strong>{" "}
          Szczególnie gdy przeplatamy te treningi z{" "}
          <strong>Cardiomasakrami.</strong>
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Jak już wyżej wspomniałam, w treningu full body workout wykonujemy{" "}
          <strong>
            ćwiczenia angażujące wiele grup mięśniowych podczas jednej sesji
            treningowej.
          </strong>{" "}
          Trenując tym planem kilka razy w tygodniu,{" "}
          <strong>dajemy więc naszym mięśniom bodźce znacznie częściej,</strong>{" "}
          niż w przypadku gdybyśmy trenowały każdą partię osobno. W przypadku
          FBW na każdej sesji treningowej angażujemy do pracy te partie.{" "}
          <strong>
            U osób mniej zaawansowanych, idealnie wpłynie to na rozwój naszego
            ciała, natomiast jeśli mówimy o tych bardziej doświadczonych w
            treningu Sarenkach, pozwoli na przełamanie stagnacji i pomoże w
            poprawie mankamentów sylwetki.
          </strong>
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          System treningu full body workout jest bardzo wszechstronny, co
          przygotowuje też nasze ciało i mięśnie do innego rodzaju wysiłku.
          Podczas wykonywania poszczególnych ćwiczeń,
          <strong>
            bardzo istotna jest poprawna technika, odpowiednie prowadzenie
            ruchu, spinanie mięśni i kontrolowanie oddechu.
          </strong>{" "}
          Są to podstawy, które każda osoba powinna znać, dlatego postaram się
          wytłumaczyć Wam wszystko jak najbardziej szczegółowo i przejrzyście.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Ffbw%2Ffbw_main_img.png?alt=media&token=cc3deb21-a6f0-4c66-a6c0-1ee429d6bc4a"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <h2 className="display-xs medium blue-text">Trening FBW</h2>
        <div style={{ height: 16 }}></div>
        <ol className="montserrat-md semibold gray-text">
          <li>
            W każdym treningu właściwym ćwiczenia są rozpisane kolejno. Każdy
            trening zawiera od 7 do 9 ćwiczeń.
          </li>
          <li>
            Cały zestaw ćwiczeń tworzy jeden pełny trening. Ważne: nie wykonuj
            dwóch treningów w ciągu jednego dnia, ponieważ zbyt duża objętość
            treningowa może przynieść więcej szkody niż pożytku.
          </li>
          <li>
            Na początku każdego ćwiczenia znajdziesz dwie liczby – pierwsza
            oznacza liczbę serii, a druga liczbę powtórzeń w każdej serii.
          </li>
          <li>
            Postępuj zgodnie z instrukcjami, aby osiągnąć najlepsze rezultaty
            bez ryzyka przetrenowania.
          </li>
        </ol>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700 black-text">
          Przykład oznaczeń:
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          <snap style={{ color: "var(--color-purple" }}>4 x 10</snap> - oznacza
          to, że masz do wykonania 4 serie, każda z nich to 10 powtórzeń danego
          ćwiczenia.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Określenie{" "}
          <snap style={{ color: "var(--color-purple" }}>4 x 10/12</snap> -
          oznacza , że w serii masz do wykonania zakres powtórzeń między 10 a
          12, więc jeśli po 10 powtórzeniach w tym ćwiczeniu masz jeszcze trochę
          siły, to wykonaj ich więcej zgodnie z zaleceniem powyżej
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Kolejnym określeniem jakie znajdziesz jest{" "}
          <snap style={{ color: "var(--color-purple" }}>SUPERSET</snap>. Na
          przykład: 3 x 12 superset: banded high pull + banded front raise.
          Oznacza to, że masz do wykonania 3 serie 2 ćwiczeń, robionych
          bezpośrednio po sobie, czyli nie ma tu przerwy, dopóki nie skończysz
          obydwóch ćwiczeń. Wykonaj więc 12 powtórzeń pierwszego ćwiczenia,
          jakim jest banded high pull i zaraz po, bez przerwy 12 powtórzeń
          banded front raise. Dopiero po tym następuje przerwa przed kolejną
          serią. A serie łącznie są trzy.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Kolejne oznaczenia, które znajdziesz w treningu są następujące:{" "}
          <snap style={{ color: "var(--color-purple" }}>
            each side/each leg/each arm{" "}
          </snap>{" "}
          - oznacza to, że masz do wykonania określoną liczbę serii i powtórzeń,
          gdzie liczba powtórzeń określona jest na jedną stronę/nogę/rękę.
          Wykonanie powtórzeń na dwie strony to jedna cała seria
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Pamiętaj, że najpierw musisz wykonać wszystkie serie i powtórzenia
          pierwszego ćwiczenia, by przejść do kolejnego. Nie możesz przerywać w
          połowie serii jednego ćwiczenia i przechodzić do kolejnego, a
          następnie wracać do poprzedniego. Jest to błąd.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Czas przerwy między seriami staraj się utrzymać między 45s a 90s.
          Natomiast przerwa między ćwiczeniami powinna trwać nie krócej niż 1min
          i nie dłużej niż 3min.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Ffbw%2Ffbw_img_2.png?alt=media&token=d00f3cbb-5973-48ef-93c1-2e2deecdf1a9"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <h2 className="display-xs medium blue-text">Skalowanie</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Skalowanie to nic innego jak właśnie dostosowywanie danego
          treningu/ćwiczenia/obciążenia czy oporu gumy do swoich aktualnych
          możliwości. Z czasem, gdy będziecie regularnie trenować, zaczniecie
          progresować i Wasze ciało będzie stać na więcej.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Początkujące osoby, jeśli nie są w stanie wykonać treningu dokładnie
          tak jak rozpisałam, mogą na początku nieco ułatwiać sobie ten trening,
          czyli właśnie skalować.
        </p>
        <p className="montserrat-md semibold">
          Przykładowo, zamiast pełnej pompki - wykonaj pompkę na kolanach. Jeśli
          nie potrafisz skakać podwójnych skoków na skakance (DU) - wykonuj
          pojedyncze skoki (SU) lub pajacyki.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Tylko Ty tak naprawdę decydujesz na jakim obciążeniu wykonasz zadanie.
          Staraj się dobierać ciężar tak, abyś była w stanie prawidłowo
          technicznie wykonać każde ćwiczenie.
        </p>
        <div style={{ height: 42 }}></div>
        <h2 className="display-xs medium blue-text">Technika</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Chciałabym, żeby każdy z Was skupił się na odpowiednim tempie. Co mam
          na myśli? Zawsze ruch negatywny - opuszczania, odwodzenia staraj się
          wykonywać wolnej, niż ruch wyciskania, przywodzenia. Może brzmi to
          skomplikowanie na pierwszy rzut oka, ale jest to tak naprawdę bardzo
          proste i wystarczy kilka treningów, by nabrać odpowiedniego nawyku
          ruchowego. W momencie, gdy zwolnisz swój ruch przy wykonywaniu
          ćwiczeń, będziesz w stanie o wiele łatwiej utrzymać prawidłową
          technikę oraz wydłużysz czas napięcia mięśni podczas serii, co w
          treningu domowym jest kluczowe, ponieważ nie bazujemy tu na dużych
          obciążeniach. Oczywiście nie chodzi o to, że od razu, od pierwszego
          treningu masz robić wszystko idealnie. Mało kto tak potrafi. :) Po
          prostu staraj się zwracać uwagę na te wskazówki, które dałam Ci
          powyżej i ciesz się treningiem!
        </p>
        <div style={{ height: 42 }}></div>
        <h2 className="display-xs medium blue-text">Dobór obciążenia </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Ciężar oraz opór gumy dobieraj tak, żebyś mogła wykonać całą serię (na
          przykład 10 powtórzeń) prawidłowo technicznie
        </p>
        <ul className="montserrat-md medium">
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>
              Za ciężkie obciążenie
            </span>
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - to takie, gdy masz na przykład do wykonania 10 powtórzeń w serii
              i w trakcie 5-6 powtórzenia nie jesteś w stanie już utrzymać
              prawidłowej techniki. Jest to błąd i nie da zamierzonych efektów.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>
              Za lekkie obciążenie
            </span>
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - to takie, gdy masz do wykonania na przykład 10 powtórzeń w serii
              i po ich wykonaniu, nie czujesz tzw. palenia mięśni. Czyli
              najzwyczajniej w świecie nie zmęczyłaś się i wykonanie tej serii
              nie sprawiało Ci żadnego trudu.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>
              Optymalne, prawidłowe obciążenie
            </span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              - to takie, gdy masz do wykonania w serii przykładowo 10 powtórzeń
              i przy 7-8-9-10 powtórzeniu czujesz tzw. mocne palenie mięśni, ale
              jesteś w stanie utrzymać do końca serii prawidłową technikę
              ćwiczeń.
            </snap>
          </li>
        </ul>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Ffbw%2Ffbw_img_3.png?alt=media&token=f8bb2627-fc56-41c8-8738-59a04c10a6fc"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 16 }}>
          <div style={{ height: 24 }}></div>
        </div>
      </div>
    ),
  },
  3: {
    id: 3,
    tag: "Porady",
    mainTitle: "Jak trenować z programami?",
    description:
      "Ten artykuł ma na celu przedstawienie Wam mojej recepty na „sarenkową” sylwetkę. Niejednokrotnie wspominałam już o tym, że w swoim życiu trenowałam chyba na wszystkie mo...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhow2start%2Fhow_to_start_main_img.png?alt=media&token=4e108361-85b4-4ab7-b467-4df4c5bcb414",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">
          Niejednokrotnie wspominałam już o tym, że w swoim życiu trenowałam
          chyba na wszystkie możliwe sposoby. Niestety w większości przypadków
          albo efekty były niezadawalające albo trening po prostu mi się nudził.
        </h1>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Ja trenuję według takiego schematu:
        </h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Ten artykuł ma na celu przedstawienie Wam mojej recepty na{" "}
          <strong>„sarenkową” sylwetkę.</strong> Niejednokrotnie wspominałam już
          o tym, że w swoim życiu trenowałam chyba na wszystkie możliwe sposoby.
          Niestety w większości przypadków albo efekty były niezadawalające albo
          trening po prostu mi się nudził. Moje doświadczenie sprawiło, że w
          końcu wynalazłam swój tak zwany{" "}
          <strong>złoty środek, którym się z Wami dzielę.</strong>
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          W pierwszej kolejności przedstawię mój plan tygodniowy pod względem
          treningów. Następnie, w zależności od tego, ile czasu możesz poświęcić
          na dni treningowe - <strong>3/4/5</strong> zaproponuję Ci w jaki
          sposób należy połączyć ze sobą treningi w skali tygodnia, żebyś
          prawidłowo mogła ułożyć swój <strong>plan treningowy. </strong>
        </p>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Ja trenuję według takiego schematu:
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Poniedziałek -{" "}
          <span style={{ color: "var(--color-purple" }}>Cardiomasakra</span>
        </p>
        <p className="montserrat-md medium grey-700">
          Wtorek - <span style={{ color: "var(--color-purple" }}>FBW</span>
        </p>
        <p className="montserrat-md medium grey-700">
          Środa - <span style={{ color: "var(--color-purple" }}>Rest Day</span>
        </p>
        <p className="montserrat-md medium grey-700">
          Czwartek -{" "}
          <span style={{ color: "var(--color-purple" }}>Cardiomasakra</span>
        </p>
        <p className="montserrat-md medium grey-700">
          Piątek - <span style={{ color: "var(--color-purple" }}>FBW</span>
        </p>
        <p className="montserrat-md medium grey-700">
          Sobota -{" "}
          <span style={{ color: "var(--color-purple" }}>
            1h Cardio lub Speed ABS Burner
          </span>
        </p>
        <p className="montserrat-md medium grey-700">
          Niedziela -{" "}
          <span style={{ color: "var(--color-purple" }}>Rest day</span>
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Jeśli masz możliwość trenowania 5 x w tygodniu, to właśnie wyżej
          przedstawiony rozkład poszczególnych treningów w skali tygodnia
          proponuję Ci wykonywać.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Teraz przedstawię Ci kilka propozycji planu treningowego, który
          powinnaś dopasować pod siebie, zważając na swoje aktualne możliwości.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhow2start%2Fhow_to_start_img_1.png?alt=media&token=394ccb26-0a17-4a32-8431-064befe80082"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <h2 className="display-xs medium blue-text">
          Opcja Minimum - 3 dni treningowe w skali tygodnia
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Na wstępnie zaznaczę, że 3 dni treningowe to absolutne minimum, jeśli
          zależy nam na kształtowaniu naszej sylwetki. Natomiast zdaję sobie
          sprawę z tego, niektóre z Was póki co, tylko na tyle mogą sobie
          pozwolić.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          W tym planie treningowym wykonujemy:
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          2 x <span style={{ color: "var(--color-purple" }}>CARDIOMASAKRA</span>
        </p>
        <p className="montserrat-md medium grey-700">
          1 x <span style={{ color: "var(--color-purple" }}>FWB</span>
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Wybierz 3 dni w tygodniu, w które wykonasz te treningi. Poszczególne
          sesje treningowe oddzielaj rest day. Przykładowo:
        </p>
        <div style={{ height: 16 }}></div>
        {/* <p className='montserrat-md medium grey-700'>
                    Poniedziałek - <span style={{color: 'var(--color-purple'}}>Cardiomasakra</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Wtorek - <span style={{color: 'var(--color-purple'}}>Rest Day</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Środa - <span style={{color: 'var(--color-purple'}}>FBW</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Czwartek - <span style={{color: 'var(--color-purple'}}>Rest Day</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Piątek - <span style={{color: 'var(--color-purple'}}>Cardiomasakra</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Sobota - <span style={{color: 'var(--color-purple'}}>Rest Day</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Niedziela - <span style={{color: 'var(--color-purple'}}>Rest day</span>
                </p> */}
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhow2start%2Fhow_to_start_img_2.png?alt=media&token=fc96d68f-1dda-4770-b2e8-13ab531aab72"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Pamiętaj, w tym planie treningowym ważne jest to, żeby dni odpoczynku
          spędzać aktywnie. Czyli przykładowo staraj się w te dni, zaliczać
          chociaż 30 min spaceru. Poza treningowa aktywność fizyczna jest
          również bardzo istotna.
        </p>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Opcja Optimum - 4 dni treningowe w skali tygodnia
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          W tym planie treningowym wykonujemy:
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          2 x <span style={{ color: "var(--color-purple" }}>CARDIOMASAKRA</span>
        </p>
        <p className="montserrat-md medium grey-700">
          2 x <span style={{ color: "var(--color-purple" }}>FWB</span>
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Treningi wykonujemy naprzemian. Przykładowo:
        </p>
        {/* <div style={{height: 16}}></div> */}
        {/* <p className='montserrat-md medium grey-700'>
                    Poniedziałek - <span style={{color: 'var(--color-purple'}}>Cardiomasakra</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Wtorek - <span style={{color: 'var(--color-purple'}}>FBW</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Środa - <span style={{color: 'var(--color-purple'}}>Rest Day</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Czwartek - <span style={{color: 'var(--color-purple'}}>Cardiomasakra</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Piątek - <span style={{color: 'var(--color-purple'}}>FBW</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Sobota - <span style={{color: 'var(--color-purple'}}>Rest day</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Niedziela - <span style={{color: 'var(--color-purple'}}>Rest day</span>
                </p> */}
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhow2start%2Fhow_to_start_img_3.png?alt=media&token=0f868162-b5a8-41dd-8ac0-7b19082777fe"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <h2 className="display-xs medium blue-text">
          Opcja Advanced - 5 dni treningowe w skali tygodnia
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Jest to mój plan treningowy, który przedstawiłam Wam na początku.W tym
          planie treningowym wykonujemy:
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhow2start%2Fhow_to_start_img_4.png?alt=media&token=47ce8faa-979a-463f-a5cc-81a1a6f7cdec"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        {/* <div style={{height: 16}}></div> */}
        {/* <p className='montserrat-md medium grey-700'>
                    2 x <span style={{color: 'var(--color-purple'}}>CARDIOMASAKRA</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    2 x <span style={{color: 'var(--color-purple'}}>FWB</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    1 x <span style={{color: 'var(--color-purple'}}>1h Cardio lub Speed ABS Burner</span>
                </p> */}
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Teraz Sarenko, powinnaś wybrać jedną z tych 3 opcji dla siebie:
          minimum/optimum/advanced.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Miej na uwadze fakt, że zbyt dużo treningów w skali tygodnia może
          przynieść odwrotne skutki. Nie zawsze więcej, oznacza lepiej. W trosce
          przede wszystkim o Twoje zdrowie, nie polecam przekraczać 5 dni
          treningowych.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Treningi Cardiomasakra optymalnie możesz wykonywać 2 x w tygodniu, a
          FBW do 3 x w tygodniu. Przerwa między jedną, a drugą jednostką FBW
          powinna wynosić od 24 do 48 godzin. To znaczy, żebyś nie wykonywała
          treningu dwa dni z rzędu, a oddzieliła je dniem odpoczynku bądź samym
          cardio.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Przedstawione powyżej plany treningowe są przykładami, w jaki sposób
          możesz ułożyć swój plan. Oczywiście nie muszą być to stricte te dni,
          które podałam. Chodzi o to, żebyś trzymała się podanych wyżej
          schematów, to znaczy kolejności treningów i liczby poszczególnych
          jednostek treningowych. Jeśli odpowiadają Tobie inne dni na wykonanie
          treningu, to oczywiście dopasuj to pod siebie.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Miej na uwadze to, że Twoim zadaniem jest wykonać całą pracę w skali
          tygodnia.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Nie tyle ważny jest dzień, kiedy wykonasz trening, a to żeby wykonać
          wszystkie jednostki treningowe w danym tygodniu.
        </p>
        <div style={{ height: 48 }}></div>
      </div>
    ),
  },
  4: {
    id: 4,
    tag: "Treningi",
    mainTitle: "Nie masz czasu na treningi? Spróbuj tego",
    description:
      "To krótkie treningi, które skupiają się na pośladkach i brzuchu, nie trwają dłużej niż 20 minut. Ten trening także dobrze sprawdzi się jako zastępstwo dla klasycznego cardio...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FtryThis%2Ftry_this_img.png?alt=media&token=5a02aa25-55d3-45d9-9748-ee4a2259471b",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">Speed ABS Burner</h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          To krótkie treningi, które skupiają się na pośladkach i brzuchu, nie
          trwają dłużej niż <strong>20 minut.</strong> Ten trening także dobrze
          sprawdzi się jako zastępstwo dla klasycznego cardio. Speed abs burner
          w pewnym sensie także jest Cardiomasakrą, ale zazwyczaj nie zawiera
          dodatkowego obciążenia i jest krótszą sesją treningową niż
          Cardiomasakra, bo składa się z tylko jednego zadania głównego. To
          świetne rozwiązanie, jeśli nie masz za dużo czasu na trening, ale nie
          chcesz wypaść ze swojej rutyny treningowej.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          <strong>
            PAMIĘTAJ, każda aktywność w ciągu dnia to krok do przodu! Nie
            odpuszczaj.
          </strong>
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FspeedAbsBurner%2Fspeed_abs_burner_01.png?alt=media&token=fcb8536f-de86-4521-b1f9-ee55719c38e2"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
      </div>
    ),
  },
  5: {
    id: 5,
    tag: "Porady",
    mainTitle: "Jak monitorować efekty?",
    description:
      "Skoro czytacie ten artykuł, to pewnie zależy Wam na tym aby osiągnąć swoją „wymarzoną” sylwetkę. Prędzej czy później zaczniecie się zastanawiać nad tym, kiedy zauważycie efekty treningów...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FmonitorEffects%2Fmonitor_effects_header.png?alt=media&token=edaf0132-c8ae-40b0-87b3-02072594a0f3",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">Monitorowanie efektów</h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Skoro czytacie ten artykuł, to pewnie zależy Wam na tym aby osiągnąć
          swoją „wymarzoną” sylwetkę. Prędzej czy później zaczniecie się
          zastanawiać nad tym, kiedy zauważycie efekty treningów i diety, jeśli
          faktycznie zamierzacie się stosować do tego, o czym Wam tutaj piszę.
          Poza tym nic nie motywuje nas bardziej do działania niż progres,
          widoczne zmiany.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FmonitorEffects%2Fmonitor_effects_01.png?alt=media&token=9a4f5ec1-2a25-4378-922e-756d7804f399"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Jak więc najlepiej to kontrolować?
        </h2>
        <div style={{ height: 16 }}></div>
        {/* <p className='montserrat-md semibold'>
                    Skoro czytacie ten artykuł, to pewnie zależy Wam na tym aby osiągnąć swoją „wymarzoną” sylwetkę. Prędzej czy później zaczniecie się zastanawiać nad tym, kiedy zauważycie efekty treningów i diety, jeśli faktycznie zamierzacie się stosować do tego, o czym Wam tutaj piszę. Poza tym nic nie motywuje nas bardziej do działania niż progres, widoczne zmiany.
                </p>
                <div style={{height: 16}}></div>
                <p className='montserrat-md semibold'>
                    Skoro czytacie ten artykuł, to pewnie zależy Wam na tym aby osiągnąć swoją „wymarzoną” sylwetkę. Prędzej czy później zaczniecie się zastanawiać nad tym, kiedy zauważycie efekty treningów i diety, jeśli faktycznie zamierzacie się stosować do tego, o czym Wam tutaj piszę. Poza tym nic nie motywuje nas bardziej do działania niż progres, widoczne zmiany.
                </p> */}
        <p className="montserrat-md semibold">
          Zacznijmy od tego, że na pewno nie zwracałabym w pierwszej kolejności
          uwagi na wagę. Dlaczego?
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold">
          Na wagę może wpływać wiele czynników - co zjedliśmy poprzedniego dnia
          i ile treści pokarmowej mamy aktualnie w przewodzie pokarmowym, ile
          godzin spaliśmy (niewysypianie się może powodować zatrzymywanie wody w
          organizmie), czy zjedliśmy dużo słonych rzeczy, w której fazie cyklu
          jesteśmy etc.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Moja waga podczas okresu często różni się nawet o 3kg. A to przecież
          wcale nie oznacza, że przytyłam w 3 dni. ;) Wagę więc odstawiłabym na
          bok, a jeśli już chcemy też kontrolować postępy w taki sposób,
          polecałabym ważenie się nie częściej niż raz w tygodniu, zawsze rano
          na czczo, po „porannej toalecie”, najlepiej w pierwszej fazie cyklu.
          Podczas menstruacji w ogóle nie radzę tego sprawdzać, bo jak już wyżej
          wspomniałam - możecie zauważyć znaczne różnice.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Weźcie też pod uwagę fakt, że u osób początkujących lub takich, które
          wracają do regularnej aktywności fizycznej po dłuższej przerwie, waga
          może stać w miejscu. Dzieje się tak na skutek dekompozycji. To znaczy
          - tkanka tłuszczowa się pali, a mięśnie zaczynają kształtować. Dlatego
          nie zawsze waga będzie dobrym wyznacznikiem tego, czy nasze starania
          niosą ze sobą progres.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Kolejnym sposobem jest sprawdzanie pomiarów ciała. Do tego potrzebny
          nam będzie centymetr krawiecki. Zanotujcie sobie wszystkie pomiary na
          samym początku.
        </p>
        <div style={{ height: 32 }}></div>
        <h2 className="display-xs medium blue-text">
          Jak prawidłowo zmierzyć obwody?
        </h2>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FmonitorEffects%2Fmonitor_effects_02.png?alt=media&token=d8fdddea-06f0-46bb-a6ae-837ae912ce13"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <p className="montserrat-md medium grey-700">
          Polecam Wam mierzyć się co 2-3 tygodnie i za każdym razem notować
          pomiary wraz z datą.
        </p>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Co według mnie jest najlepszą opcją do monitorowania postępów?
        </h2>
        <div style={{ height: 32 }}></div>
        <p className="montserrat-md medium grey-700">
          <strong>LUSTRO.</strong> Oczywiście, jeśli przeglądacie się
          codziennie, możecie nie zauważać zmian. Dlatego polecam regularnie
          wykonywać zdjęcia. Może być to zwykłe „selfie” w lustrze, najlepiej w
          bieliźnie i dziennym świetle, żeby było widać dokładnie całą sylwetkę.
          Polecam jednak ustawić w telefonie tryb samowyzwalacza i ustawić się
          do zdjęcia przodem/bokiem/tyłem. Po <strong>2-3 tygodniach</strong>{" "}
          wykonajcie podobne zdjęcia, najlepiej w tym samym miejscu i o podobnej
          porze. W taki sposób najlepiej ocenić, czy Wasza forma ulega poprawie.
        </p>
        <div style={{ height: 32 }}></div>
        <p className="montserrat-md semibold">
          Dokonaj pomiarów, zanotuj swoje dane, strzel selfie i zaczynamy!
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FmonitorEffects%2Fmonitor_effects_01.png?alt=media&token=9a4f5ec1-2a25-4378-922e-756d7804f399"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 64 }}></div>
        {/* <h2 className='display-xs medium blue-text'>Jak więc najlepiej to kontrolować?</h2>
                <div style={{height: 16}}></div>
                <p className='montserrat-md medium grey-700'>
                    Klatka piersiowa - <span style={{color: 'var(--color-purple'}}>w najszerszym miejscu, mniej więcej na wysokości sutków.</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Talia - <span style={{color: 'var(--color-purple'}}>na wysokości pępka. Nie wciągamy brzucha i nie ściskamy mocno miary.</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Biodra - <span style={{color: 'var(--color-purple'}}>najszersze miejsce na wysokości pośladków (nie tam gdzie kolce biodrowe).</span>
                </p>
                <p className='montserrat-md medium grey-700'>
                    Udo - <span style={{color: 'var(--color-purple'}}>najszersze miejsce (zazwyczaj zaraz pod pośladkiem).</span>
                </p>
                <div style={{height: 24}}></div>
                <p className='montserrat-md medium grey-700'>
                    Polecam Wam mierzyć się co 2-3 tygodnie i za każdym razem notować pomiary wraz z datą.
                </p>
                <div style={{height: 16}}></div>
                <h2 className='display-xs medium blue-text'>Co według mnie jest najlepszą opcją do monitorowania postępów?</h2>
                <div style={{height: 16}}></div>
                <p className='montserrat-md medium grey-700'>
                    <strong>LUSTRO</strong>. Oczywiście, jeśli przeglądacie się codziennie, możecie nie zauważać zmian. Dlatego polecam regularnie wykonywać zdjęcia. Może być to zwykłe „selfie” w lustrze, najlepiej w bieliźnie i dziennym świetle, żeby było widać dokładnie całą sylwetkę. Polecam jednak ustawić w telefonie tryb samowyzwalacza i ustawić się do zdjęcia przodem/bokiem/tyłem. Po <strong>2-3 tygodniach</strong> wykonajcie podobne zdjęcia, najlepiej w tym samym miejscu i o podobnej porze. W taki sposób najlepiej ocenić, czy Wasza forma ulega poprawie.
                </p>
                <div style={{height: 16}}></div>
                <p className='montserrat-md semibold'>
                    Dokonaj pomiarów, zanotuj swoje dane, strzel selfie i zaczynamy!
                </p>
                <div style={{height: 32}}></div>
                <img src='https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2FmonitorEffects%2Fmonitor_effects_img.png?alt=media&token=3000db95-29f8-4fb5-945a-7c6ba73471a6' style={{maxWidth: '100%'}}></img>
                <div style={{height: 32}}></div> */}
      </div>
    ),
  },
  6: {
    id: 6,
    tag: "Motywacja",
    mainTitle: "Poznaj Cardiomasakrę",
    description:
      "Cardiomasakra jest programowaniem treningowym opracowanym w oparciu o podstawy treningu Crossfit oraz treningu funkcjonalnego. Metodologia jest bardzo zbliżona do klasycznego Crossfitu. To znaczy, że...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fcardiomasakra%2Fcardiomasakra_header_03.png?alt=media&token=9cbd4118-81fd-429b-8a83-bc23b9e88295",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">
          CARDIOMASAKRA - czym jest dokładniej?
        </h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Cardiomasakra jest programowaniem treningowym opracowanym w oparciu o
          podstawy treningu Crossfit oraz treningu funkcjonalnego. Metodologia
          jest bardzo zbliżona do klasycznego Crossfitu. To znaczy, że poruszamy
          się na trzech płaszczyznach: cardio, gimnastyka i podnoszenie
          ciężarów. W klasycznym Crossficie praca odbywa się zarówno na małym
          jak i dużym obciążeniu. Natomiast założeniem Cardiomasakry jest praca
          na niższym obciążeniu, ponieważ nie chcemy budować dużej masy
          mięśniowej, a bardziej skupić się na:
        </p>
        <div style={{ height: 16 }}></div>
        <ul className="montserrat-md medium">
          <li style={{ color: "var(--color-purple" }}>
            spalaniu zbędnej tkanki tłuszczowej,
          </li>
          <li style={{ color: "var(--color-purple" }}>modelowaniu sylwetki,</li>
          <li style={{ color: "var(--color-purple" }}>
            i przede wszystkim budowaniu ogólnej sprawności naszego ciała.
          </li>
        </ul>
        <p className="montserrat-md medium grey-700">
          Poprzez odpowiednie zaprogramowanie ćwiczeń, łącząc wszystkie trzy,
          wyżej wspomniane płaszczyzny w jeden trening, wykonamy pełną sesję
          treningową, która sprawi, że w krótkim czasie uzyskamy bardzo dobre
          rezultaty. Zarówno jeśli chodzi o kształtowanie sylwetki jak i ogólną
          sprawność naszego ciała.
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fcardiomasakra%2Fcardiomasakra_default_02.png?alt=media&token=09c2f7a6-f9e4-4000-bb3d-5c59f57a05c2"
          style={{ maxWidth: "100%" }}
        ></img>
        <div style={{ height: 48 }}></div>
        <p className="montserrat-md medium grey-700">
          Cardiomasakra jest jedynym W swoim rodzaju planem treningowym, w
          którym ŻADNA SESJA TRENINGOWA NIE JEST TAKA SAMA. Dzięki takiej
          formule jesteśmy w stanie za każdym razem dawać naszemu ciału inny
          bodziec do pracy. Przez to unikniemy tzw. stagnacji, czyli braku
          efektów i zastoju treningowego. Warto wspomnieć również o tym, że
          jednym z największych plusów Cardiomasakry jest brak monotonii
          treningowej. Każdy trening traktujemy jak nowe wyzwanie, przez co po
          prostu nie jest on nudny. To jest niezwykle istotne, ponieważ jeśli
          trenujemy w taki sposób, który po prostu sprawia nam przyjemność
          jesteśmy w stanie kontynuować to przez dłuższy czas. Zarówno jeśli
          chodzi o kształtowanie sylwetki, jak i poprawę kondycji, czy ogólnej
          sprawności ważny jest właśnie czas i cierpliwość.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Ta forma treningu jest przeznaczona dla każdej zdrowej osoby. Bez
          względu na wiek i staż treningowy. Zachęcam także męskie grono do
          trenowania systemem Cardiomasakra. W tym przypadku wystarczy nieco
          zwiększyć obciążenie. Zarówno osoba początkująca jak i zaawansowana
          będzie w stanie wykonać mocną jednostkę treningową. Ostrożność jednak
          powinny zachować osoby, które mają problem z dużą nadwagą, cukrzycą
          czy układem sercowo- naczyniowym.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Pamiętajmy o tym, że to tylko my sami decydujemy o intensywności
          naszego treningu i dopasowujemy go pod swój aktualny poziom
          wytrenowania. Zarówno ciężary, które są określone w treningach możemy
          obniżać, jak i skalować poszczególne ćwiczenia.
        </p>
        <div style={{ height: 16 }}></div>
        <h2 className="display-xs medium blue-text">Co musisz wiedzieć?</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Każda Cardiomasakra składa się z kilku zadań, na początku każdej
          Cardiomasakry mamy tak zwany WARM UP, czyli rozgrzewkę właściwą,
          przygotowaną pod trening, który będziesz wykonywać za chwilę. Przed tą
          rozgrzewką zalecam wykonać dodatkowo krótki stretching. Między każdym
          zadaniem (filmem) zazwyczaj jest 5 minut odpoczynku.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Trening Cardiomasakra może wykonywać każda zdrowa osoba. Zarówno taka,
          która swoją przygodę z aktywnością fizyczną dopiero zaczyna, ale także
          o wiele bardziej zaawansowana. Cardiomasakra jest idealnym narzędziem
          do tego, by właśnie tę ogólną sprawność poprawiać. Ten trening na
          pewno pomoże Wam wypracować kondycję, bo pamiętajmy, że nikt nie
          urodził się od razu w świetnej formie. To wymaga pracy, nic nie
          przychodzi ot tak, po prostu.
        </p>
        <div style={{ height: 16 }}></div>
        <ul className="montserrat-md medium">
          <li>
            Lepiej jest{" "}
            <span style={{ color: "var(--color-purple" }}>
              zacząć treningi na mniejszym obciążeniu i z czasem, gdy będziemy
              czuli, że trening staje się coraz mniej wymagający{" "}
            </span>
            - zwiększać je. W momencie, gdy jesteś już na tyle wytrenowana, że
            praca na obciążeniu gumy 25kg i hantlach 12kg (każdy) nie sprawia
            żadnych problemów technicznych, wtedy należy zwiększyć intensywność
            i tempo pracy, czyli po prostu staramy się wykonać dane zadanie jak
            najszybciej.
          </li>
          <li>
            Jeśli dopiero zaczynasz przygodę z Cardiomasakrą, pierwszą
            rundę/obwód zawsze traktuj jako „testowy" i sprawdzaj, czy jesteś w
            stanie wykonać wszystkie powtórzenia w określonym reżimie czasowym.
            Jeśli nie, to od kolejnej rundy/obwodu zmniejsz liczbę powtórzeń.
          </li>
          <li>
            W przypadku takich formuł jak AMRAP, czy RFT, FOR TIME etc. nie
            zmniejszaj liczby powtórzeń, tylko po prostu rób dłuższe, bądź
            częstsze przerwy w trakcie wykonywania danego zadania. Zapisuj czas,
            w jakim udało Ci się wykonać dane zadanie, czy liczbę obwodów w
            trakcie AMRAPu. Zobaczysz, że za kilka tygodni zrobisz je o wiele
            szybciej i zapewne na większym obciążeniu! :)
          </li>
        </ul>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">JUST KEEP WORKING!</p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Pomimo tego, że na filmach treningowych tłumaczę dokładnie wszystkie
          skróty i formuły, przygotowałam dla Ciebie LEGENDĘ SKRÓTÓW używanych w
          Cardiomasakrze. Cardiomasakra bazuje na metodologii treningu Crossfit,
          który wywodzi się z USA. Niektóre ćwiczenia nie mają dokładnych
          tłumaczeń w języku polskim, bądź dosłowne tłumaczenia są bardzo długie
          i niejasne, dlatego trzymam się terminologii z języka angielskiego.
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fcardiomasakra%2Fcardiomasakra_default_01.png?alt=media&token=1ea39d01-56d6-4565-8509-2a000fe7b6fa"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">Legenda skrótów</h2>
        <ul className="montserrat-md medium">
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>STRETCHING</span>
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - rozciąganie przed każdym treningiem.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>WARM UP</span>
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - rozgrzewka właściwa.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>REST</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              - (ang. przerwa) - to określenie pojawia się między każdym WOD -
              oznacza to, że masz przykładowo 5' przerwy zanim zaczniesz
              wykonywać następne zadanie. Znajdziesz je także w niektórych
              zadaniach - na przykład określenie czasu odpoczynku między seriami
              w danym zadaniu.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>DU</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              - podwójne skoki na skakance.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>SU</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              - pojedyncze skoki na skakance.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>REPS</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              (ang. repetition) - liczba powtórzeń danego ćwiczenia.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>SETS/ROUNDS</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - liczba setów/rund (używane zamiennie) do wykonania w danym
              treningu. W niektórych zadaniach podany jest konkretny czas
              odpoczynku między setami/rundami.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>EMOM</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              (ang. every minute on the minute) - wykonujemy określoną liczbę
              powtórzeń danego ćwiczenia w ciągu jednej minuty. Jeśli skończymy
              wszystkie powtórzenia przed upływem danej minuty, odpoczywamy do
              końca tej minuty. Kolejny element zaczynamy od kolejnej minuty.{" "}
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>AMRAP</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              (ang. as many reps/rounds as possible) - wykonujemy maksymalną
              liczbę rund i powtórzeń danych ćwiczeń w określonym czasie.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>For time</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              wykonanie określonych ćwiczeń w wyznaczonej liczbie powtórzeń
              możliwie jak najszybciej, bez ograniczenia czasowego.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>RFT</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              (ang. rounds for time) - określona liczba ćwiczeń i powtórzeń do
              wykonania na czas, w określonej liczbie rund.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>TABATA</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - zestaw ćwiczeń (lub jednego ćwiczenia) w schemacie: 8 rund, 20s
              pracy/10s przerwy.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>TURBO TABATA</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              - zestaw ćwiczeń (lub jednego ćwiczenia) w schemacie: 8 rund, 40s
              pracy/20s przerwy. W zwykłej rabacie jest to 8 rund: 20s pracy i
              10s przerwy.
            </snap>
          </li>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>WINDOW</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              - oznacza okno czasowe, w którym jest do wykonania zadanie
              składające się z jednego ćwiczenia lub zestawu ćwiczeń. Jeśli
              zadanie zostanie wykonane przed upływem określonego czasu w tym
              oknie, pozostały czas odpoczywamy.
            </snap>
          </li>
        </ul>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          '- jeden apostrof oznacza minuty
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          "- dwa apostrofy oznaczają sekundy
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          5+5- jeśli przy jakimś ćwiczeniu pojawia się taki zapis liczby
          powtórzeń oznacza to, że masz do wykonania po 5 powtórzeń danego
          ćwiczenia na każdą rekę/nogę/stronę etc.
        </p>
        <ul>
          <li style={{ color: "var(--color-purple" }}>
            <span style={{ color: "var(--color-purple" }}>JUMPING ROPE</span>{" "}
            <snap style={{ color: "var(--color-navy" }}>
              {" "}
              - skoki na skakance, dowolny styl.
            </snap>
          </li>
        </ul>
      </div>
    ),
  },
  7: {
    id: 7,
    tag: "Dieta",
    mainTitle: "Healthy Mind - jak ugryźć odżywianie",
    description:
      "Zacznijmy więc od psychologicznego podejścia do tematu „zdrowego stylu życia”. Większość z Was ma zapewne problem w kwestii odżywiania. Musicie zdać sobie sprawę z tego, że sposób odżywiania (celowo....",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhealthy_mind%2Fhealthymind_header.png?alt=media&token=1c601845-535f-46bd-b58f-69a744b77e22",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <div style={{ height: 24 }}></div>
        <h1 className="display-md medium blue-text">Dieta</h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Zacznijmy więc od psychologicznego podejścia do tematu „zdrowego stylu
          życia”. Większość z Was ma zapewne problem w kwestii odżywiania.
          Musicie zdać sobie sprawę z tego, że sposób odżywiania (celowo nie
          używam określenia DIETA, ale o tym później) powinien być dopasowany do
          naszego trybu życia, pracy, aktywności fizycznej itd. Nie inaczej.
          Dlaczego tak? Bo w odwrotnej kolejności zaczniemy podporządkowywać
          każdą sferę naszego życia pod zjedzenie posiłków, a nie powinno tak
          być.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Jedzenie ma za zadanie dawać nam energię i siłę do codziennego
          funkcjonowania, pracy, realizowania swoich marzeń, celów, trenowania..
          i po prostu cieszenia się życiem.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Nie będę więc pisać Wam, że powinniście jeść 5 posiłków dziennie, bo
          tak jest zdrowo. Po pierwsze - to mit, a po drugie - nie każdy ma czas
          na to, żeby jeść co 3 godziny. Każdy organizm jest inny, dlatego
          sposób żywienia przede wszystkim powinien być dopasowany pod nasz tryb
          życia i preferencje.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Myślę, że łatwiej będzie jak wyszczególnię Wam kilka istotnych
          kwestii, które po wdrożeniu do swojego życia znacznie ułatwią Wam
          podejście do tematu odżywiania.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Często w social mediach pytacie mnie o to co sądzę na temat diety
          ketogenicznej, low carb, high carb, low fat, wegańskiej itp.
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhealthy_mind%2Fhealthymind_01.png?alt=media&token=f08f929a-7fff-4041-9763-5191be5bc5ed"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Która będzie najbardziej skuteczna? Taka, którą będziecie mogli
          kontynuować na dłuższy czas.
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Dlatego właśnie staram się nie używać określenia DIETA. Według mnie to
          słowo nacechowane jest negatywnie, bo podświadomie daje nam do
          zrozumienia, że to tylko chwilowe, na pewien okres (miesiąc, dwa,
          cztery) aż do osiągnięcia zamierzonych efektów. A co dalej? Ryzyko
          efektu jojo, utracenia uzyskanej formy. Nie o to w tym wszystkim
          chodzi.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Pamiętajcie, że to nie ma być wyścig i zasada „im szybciej tym lepiej”
          się tutaj nie sprawdzi. „Dobra dieta” to taka, która pozwoli nam
          kontynuować jej założenia przez dłuższy czas lub nawet przez całe
          życie. Dzięki temu mamy gwarancję, że uda nam się utrzymać uzyskane
          efekty.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Na dłuższą metę duże restrykcje, eliminowanie całkowicie określonych
          grup produktów nie sprawdzi się. Uważam, że nie ma sensu całkowicie
          usuwać ze swojego jadłospisu nabiał czy gluten (o ile ktoś naprawdę
          nie ma nietolerancji, czy np. celiakii), bo prędzej czy później albo
          będziemy zmęczeni tym, że nie możemy sobie pozwolić raz na jakiś czas
          na pizzę albo w dalszej perspektywie czasu doprowadzimy do niedoborów.
        </p>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Najskuteczniejsza dieta?
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          To nic innego jak ŚWIADOME DOKONYWANIE WYBORÓW ŻYWIENIOWYCH I
          ZBILANSOWANY SPOSÓB ODŻYWIANIA.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Nawiązując do poprzedniego stwierdzenia, nie powinniśmy eliminować
          całkowicie pewnych grup produktów z jadłospisu, ale mając na uwadze
          redukcję tkanki tłuszczowej i osiągnięcie zamierzonych celów
          sylwetkowych, niektóre potrawy powinniśmy zdecydowanie ograniczyć. Mam
          tu na myśli: fast foody, słodycze, słone przekąski typu chipsy,
          chrupki, orzeszki, słodkie napoje, cukier do kawy czy herbaty itp. Nie
          mówię, że na zawsze, bo sama czasem jem takie rzeczy, ale na pewno nie
          stanowią one fundamentu mojego sposobu odżywiania. Jeśli takie
          produkty pojawiają się w Waszym jadłospisie codziennie lub nawet co
          dwa dni - zmianę swojego dotychczasowego stylu życia powinniście
          zacząć właśnie od ograniczenia tego rodzaju pożywienia. Inaczej ciężko
          będzie Wam zrobić formę.
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhealthy_mind%2Fhealthymind_02.png?alt=media&token=ed51741c-4bee-4cf2-bac7-58b098b8604f"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 48 }}></div>
        <h2 className="display-xs medium blue-text">
          Jem zdrowo, trenuję, a efektów brak
        </h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Część z Was myśli sobie teraz pewnie:{" "}
          <strong>
            „Ale ja przecież nie jem takich rzeczy od dawna, staram się jeść
            zdrowo, ćwiczę regularnie, a efektów brak”.
          </strong>{" "}
          Już Wam tłumaczę gdzie może leżeć problem.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          To, czy chudniemy/tyjemy/utrzymujemy aktualną wagę zależy od tego ile
          kalorii z pożywienia dostarczamy swojemu organizmowi w ciągu dnia, a
          ile jej zużywamy.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Zanim przejdziemy do obliczania swojego zapotrzebowania
          energetycznego, przytoczę Wam pewien przykład:
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          <strong>
            Kasia ma 26 lat, waży 60kg, ma 168cm wzrostu, trenuje 3 x w tygodniu
          </strong>
          , od poniedziałku do piątku pracuje w korporacji i 8h dziennie siedzi
          przed komputerem za biurkiem, do pracy dojeżdża samochodem. Obliczając
          jej podstawową przemianę materii uwzględniając powyższe czynniki (o
          tym jak to obliczyć napiszę w dalszej części) wynika, że aby
          utrzymywać swoją aktualną formę (nie tyć i nie chudnąć) powinna jeść
          około 2100 kalorii (dokładnie policzymy to później).
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Kasi zależy na tym, żeby schudnąć parę kilogramów, ale pomimo tego, że
          je zdrowo- efektów brak… jadłospis Kasi wygląda tak:
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhealthy_mind%2Fhealthymind_03.png?alt=media&token=18823cdc-562b-4204-a802-01d531947d82"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 48 }}></div>
        {/* <h2 className='display-xs medium blue-text'>Śniadanie 8:00</h2>
                <div style={{height: 16}}></div>
                <p className='montserrat-md semibold'>
                    Owsianka:
                </p>
                <p className='montserrat-md medium grey-700'>
                    50g płatków owsianych (183 kcal),
                </p>
                <p className='montserrat-md medium grey-700'>
                    1 średni banan (116 kcal), 
                </p>
                <p className='montserrat-md medium grey-700'>
                    łyżka masła orzechowego (220 kcal),
                </p>
                <p className='montserrat-md medium grey-700'>
                    suszone morele (115 kcal), 
                </p>
                <p className='montserrat-md medium grey-700'>
                    mleko roślinne (50 kcal)
                </p>
                <div style={{height: 16}}></div>
                <p className='montserrat-md semibold'>
                    Z samego śniadania wychodzi około 687 kalorii.
                </p>
                <div style={{height: 24}}></div>
                <h2 className='display-xs medium blue-text'>II Śniadanie 10:30</h2>
                <div style={{height: 16}}></div>
                <p className='montserrat-md medium grey-700'>
                    Batonik wegański z daktyli (180 kcal)
                </p>
                <p className='montserrat-md medium grey-700'>
                    1 jabłko (80 kcal)
                </p>
                <div style={{height: 16}}></div>
                <p className='montserrat-md medium grey-700'>
                    <strong>Łącznie 260 kcal… </strong>aha jeszcze cappucino z mlekiem 2% założmy - dodatkowe 150 kcal, czyli jeszcze nie było południa, a już 1097 kcal na liczniku, czyli ponad połowa dziennego zapotrzebowania kalorycznego Kasi.. ale idźmy dalej.
                </p>
                <div style={{height: 24}}></div>
                <h2 className='display-xs medium blue-text'>Obiad 14:00</h2>
                <div style={{height: 16}}></div>
                <p className='montserrat-md medium grey-700'>
                    Też zdrowo, bo „tylko sałatka” Awokado Rybak (wersja powiększona, bo kolejny posiłek Kasia zje dopiero w domu) z popularnej sieciówki <strong>(667 kcal)</strong>, do tego dressing balsamico (334 kcal).
                </p>
                <div style={{height: 16}}></div>
                <p className='montserrat-md medium grey-700'>
                    Po obiedzie jeszcze jedno cappucino (ok. 150 kcal). Łącznie wpadło <strong>1151</strong> kcal podczas obiadu.
                </p> */}
        <div style={{ height: 24 }}></div>
        <h2 className="display-xs medium blue-text">Podsumowanie</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          I tutaj pozwolę się zatrzymać. Powyższy jadłospis to oczywiście tylko
          przykład. Dlaczego go Wam przytaczam?
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Chcę żebyście zwrócili uwagę na to, że posiłki Kasi to faktycznie
          zdrowe wybory, ale spójrzmy na kaloryczność.{" "}
          <strong>Jest godzina 14:00, a na liczniku już 2168 kalorii.</strong>{" "}
          Przypomnę, że ZERO kaloryczne Kasi wynosi 2100 kalorii. Oznacza to, że
          Kasia już prawdopodobnie przekroczyła swoje dzienne zapotrzebowanie, a
          przed nią jeszcze co najmniej jeden posiłek. Nawet jeśli dokona
          kolejnego zdrowego wyboru, to i tak przekroczyła już swój dzienny
          bilans, przez co na pewno nie zredukuje wagi, a wręcz przeciwnie, może
          nawet przytyć.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Dlatego według mnie, najlepszą i najbardziej efektywną opcją w drodze
          do osiągnięcia „wymarzonej” formy będzie kontrolowanie spożywanych
          kalorii.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold">
          Więcej na temat obliczania swojego zapotrzebowania kalorycznego
          dowiesz się z osobnego artykułu.
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhealthy_mind%2Fhealthymind_04.png?alt=media&token=4ef85427-5031-48c4-8e70-df97040d139a"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 64 }}></div>
      </div>
    ),
  },
  8: {
    id: 8,
    tag: "Porady",
    mainTitle: "Oblicz swoje zapotrzebowanie kaloryczne",
    description:
      "Nie wiesz jak obliczyć ile kalorii powinniśmy spożywać mając na uwadze osiągnięcie założonych celów? Dzienne zapotrzebowanie kaloryczne zwane jest inaczej metabolizmem...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fkcal_control%2Fnutri_calculator_header_01.png?alt=media&token=ae923a79-c721-4757-a292-471c96d7fe6c",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <div style={{ height: 24 }}></div>
        <h1 className="display-md medium blue-text">Dieta</h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Nie wiesz jak obliczyć ile kalorii powinniśmy spożywać mając na uwadze
          osiągnięcie założonych celów? Dzienne zapotrzebowanie kaloryczne zwane
          jest inaczej metabolizmem. Tłumacząc, określa ilość energii, jaką w
          ciągu całej doby nasz organizm zużywa na wszystkie procesy niezbędne
          do prawidłowego funkcjonowania. Mam tu na myśli pracę mózgu czy innych
          narządów (jelita, żołądek etc.), praca, trening i wszystkie inne
          aktywności, które wykonujemy w ciągu dnia.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Pamiętajmy jednak, że obliczenia to wartość szacunkowa. W internecie
          znajdziemy setki różnych kalkulatorów. Nie jesteśmy jednak w stanie
          obliczyć zapotrzebowania w 100% dokładnie, bo jest zbyt wiele
          zmiennych, które wpływają na dzienne zużycie kalorii.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Zapotrzebowanie kaloryczne to w skrócie CPM (Całkowita Przemiana
          Materii), natomiat podstawą CPM jest PPM (Podstawowa Przemiana
          Materii, określana także jako BMR). PPM określa liczbę kilokalorii,
          która jest niezbędna do podtrzymania podstawowych funkcji życiowych i
          prawidłowego funkcjonowania organizmu, czyli np. trawienie, sen,
          oddychanie, utrzymywanie prawidłowej temperatury ciała. Nie uwzględnia
          żadnej aktywności.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Jeśli już wiemy czym jest CPM i PPM, możemy przejść do obliczeń. Jak
          już wcześniej wspomniałam, istnieje wiele kalkulatorów, metod liczenia
          zapotrzebowania kalorycznego. Posłużę się poniżej jedną z nich, według
          mnie najwygodniejszą.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Zacznijmy od obliczenia PPM (podstawowa przemiana materii) na
          podstawie wzoru Mifflina:
        </p>
        <div style={{ height: 48 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fkcal_control%2Fnutri_calculator_01.png?alt=media&token=4ca64b54-6d58-467f-9e1e-4e8d1c246d88"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Wzory nieco się różnią, bo kobiety są po prostu mniejsze od mężczyzn,
          mają inną genetykę, mniej masy mięśniowej, a więcej tkanki
          tłuszczowej. Taka nasza natura niestety.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Jak to wygląda w praktyce? Wróćmy do przykładu Kasi z poprzedniego
          artykułu:
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          PPM = (9,99 x 60kg) + (6,25 x 168cm) - (4,92 x 26 lat) - 161 = 1360,48
          kcal
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Okej, czyli wiemy już, że organizm Kasi do prawidłowego funkcjonowania
          potrzebuje około 1360,48 kcal. To jest minimum, które Kasia powinna
          jeść, aby wszystkie funkcje organizmu mogły wykonywać swoją pracę.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Idźmy krok dalej. Kasia na co dzień pracuje przy komputerze i spędza
          za biurkiem około 8h dziennie, 3 x w tygodniu chodzi na siłownię.
          Należy więc przemnożyć wyżej otrzymany wynik przez współczynnik
          aktywności.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Tutaj posłużymy się wzorem Harrisa Benedicta:
        </p>
        <div style={{ height: 0 }}></div>
        <ul className="montserrat-md semibold grey-700">
          <li>
            <p className="montserrat-md semibold grey-700">
              BRAK AKTYWNOŚCI - mnożymy przez 1,2 - dla osoby chorej/leżącej
              całymi dniami w łóżku, z bardzo ograniczoną aktywnością.
            </p>
          </li>
          <li>
            <p className="montserrat-md semibold grey-700">
              ŚREDNIA AKTYWNOŚĆ - mnożymy przez 1,55 - dla osoby z umiarkowaną
              aktywnością fizyczną np. trening 3-5 x w tygodniu
            </p>
          </li>
          <li>
            <p className="montserrat-md semibold grey-700">
              MAŁA AKTYWNOŚĆ - mnożymy przez 1,375 - dla osoby z małą
              aktywnością fizyczną, np. trening 1-3 x w tygodniu
            </p>
          </li>
          <li>
            <p className="montserrat-md semibold grey-700">
              DUŻA AKTYWNOŚĆ - mnożymy przez 1,725 dla osoby prowadzącej aktywny
              tryb życia np. praca w biegu/fizyczna, trening minimum 5 x w
              tygodniu
            </p>
          </li>
          <li>
            <p className="montserrat-md semibold grey-700">
              BARDZO DUŻA AKTYWNOŚĆ - mnożymy przez 1,9 - dla osoby prowadzącej
              bardzo aktywny tryb życia, praca fizyczna, trening codziennie
            </p>
          </li>
        </ul>
        <div style={{ height: 4 }}></div>
        <p className="montserrat-md semibold grey-700">
          W przypadku Kasi wybierzemy opcje numer 2., bo większość czasu spędza
          siedząc za biurkiem, a na treningu pojawia się 3 x w tygodniu.
          Obliczenia wyglądają więc następująco:
        </p>
        <div style={{ height: 0 }}></div>
        <ul className="montserrat-md semibold grey-700">
          <li>
            <p className="montserrat-md semibold grey-700">
              CPM = 1360,48 kcal x 1,375 = 1870,66 kcal
            </p>
          </li>
          <li>
            <p className="montserrat-md semibold grey-700">
              Otrzymany wynik to właśnie liczba kalorii, jaką Kasia powinna
              przyjmować, jeśli chce utrzymywać aktualną wagę.
            </p>
          </li>
          <li>
            <p className="montserrat-md semibold grey-700">
              Jedząc ~ 1870 kcal Kasia ani nie będzie tyć, ani też nie schudnie.
              To jest jej tzw.{" "}
              <snap style={{ color: "var(--color-purple" }}>
                ZERO KALORYCZNE
              </snap>
              .
            </p>
          </li>
        </ul>
        <div style={{ height: 4 }}></div>
        <p className="montserrat-md semibold grey-700">
          Wróćmy jeszcze na chwilę do punktu, w którym oszacowywaliśmy poziom
          aktywności Kasi. Pewnie zastanawiacie się dlaczego w tym przypadku
          została wybrana MAŁA aktywność fizyczna? Po pierwsze - większość dnia
          w pracy spędza siedząc za biurkiem, czyli praktycznie się nie rusza,
          do pracy dojeżdża samochodem, a nie np. rowerem, co byłoby brane pod
          uwagę jako dodatkowa aktywność fizyczna.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Kasia trenuje 3 x tygodniu przez około 1-1,5h, ale po powrocie do domu
          zjada kolację i włącza serial. Nie ma psa, więc nie musi wyjść na
          spacer. Reasumując, pozatreningowa aktywność fizyczna Kasi jest
          niewielka, a wbrew pozorom podczas treningu wcale spalamy aż tyle
          kalorii, ile mogłoby się nam wydawać.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Oszacowując więc współczynnik aktywności powinniśmy zwrócić szczególną
          uwagę nie tylko na to jak często i jak ciężko trenujemy, ale na to jak
          wygląda reszta naszego dnia.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Ile kroków dziennie robimy, czy poruszamy się samochodem/komunikacją
          miejską, czy może dojeżdżamy do pracy rowerem lub idziemy pieszo, czy
          częściej wybieramy windę lub schody.. i wiele innych. Zwracajmy więc
          również uwagę na aktywność jaką wykonujemy poza treningiem. Podobnie w
          przypadku osób, które trenują bardzo intensywnie 7 razy w tygodniu,
          ale przez resztę dnia siedzą w domu - mała poza treningowa aktywność.
          Wówczas współczynnik aktywności nie będzie określany jako bardzo duża
          aktywność, a raczej poziom niżej.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Pamiętajmy jednak o tym, że wszystko, co omówiliśmy wyżej to tylko
          obliczenia.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Każdy organizm jest inny, nie każdy dzień spędzamy w taki sam sposób i
          nigdy nie będziemy mieć stuprocentowej pewności, że obliczenia
          sprawdzą się idealnie. Powinniśmy wziąć na to poprawkę i traktować
          jako pewien szablon, podstawę, na której będziemy się opierać, a do
          tego po prostu należy obserwować na bieżąco co dzieje się z naszym
          organizmem.
        </p>
        <div style={{ height: 42 }}></div>
        <h2 className="display-xs medium blue-text">Makroskładniki</h2>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Co dalej? Teraz czas pogadać o makroskładnikach. To bardzo istotne w
          samodzielnym układaniu zbilansowanej diety.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Skoro już obliczyliśmy tzw. zero kaloryczne, co jest naszym punktem
          wyjścia w dalszym ustalaniu diety, teraz czas pomyśleć nad podziałem
          makroskładników. Mowa o węglowodanach, białkach i tłuszczach. Jest to
          istotne, jeśli chcemy manipulować masą i składem ciała. Kaloryczność
          diety będzie wpływać na naszą wagę (chudnięcie, tycie, utrzymanie),
          natomiast proporcje makroskładników będą miały znaczenie jeśli chodzi
          o „skład” naszego ciała, tzn. ilość tkanki tłuszczowej, mięśniowej i
          także wody. Planowanie podziału makroskładników będzie się nieco
          różnić biorąc pod uwagę płeć.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          <snap style={{ color: "var(--color-purple" }}>BIAŁKO</snap> - pełni
          rolę budulcową mięśni, a także kości, stawów, włosów, paznokci etc.
          Spożywanie białka w diecie powoduje większe uczucie sytości.
          Charakteryzuje się także najwyższym efektem termicznym pożywienia, co
          oznacza, że strawienie ich oraz przyswojenie przez nasz organizm
          zużywa około 20-30% spożytych kalorii. 1g białka dostarcza 4 kcal.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fkcal_control%2Fnutri_calculator_02.png?alt=media&token=0dd0f72c-48bc-44d0-b96d-8a3c2f875357"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <h2 className="display-xs medium blue-text">
          Ile gramów białka powinniśmy spożywać?
        </h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Dla osób trenujących jest to przedział między 1,6g/kg masy ciała, a
          2,5g/kg masy ciała.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Przedział najbardziej uniwersalny to 1,8g-2g, który sprawdzi się
          zarówno podczas redukcji masy ciała, budowania, czy utrzymania.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Policzmy więc zapotrzebowanie białka dla Kasi, przyjmując wartość
          2g/kg masy ciała:
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Waga Kasi to 60kg, czyli:
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">60 x 2 = 120g</p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Kasia powinna spożywać około 120g białka dziennie. Oczywiście nic się
          nie stanie jeśli jednego dnia zje go mniej, a innego więcej.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">1g białka to 4 kcal</p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">120g białka to 480 kcal</p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Skoro już wiemy ile g czy kalorii z białka powinniśmy dostarczać na co
          dzień, spójrzmy jakie produkty je zawierają:
        </p>
        <ul className="montserrat-md medium grey-700">
          <li>
            <p className="montserrat-md medium grey-700">
              Jajka (białka jaj, żółtka zaliczamy do tłuszczów)
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Mięso, np. drób, wołowina. Najlepiej wybierać te, które zawieraj
              mniej niż 5% tłuszczu.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Ryby, jak wyżej - najlepiej chude tj. dorsz, morszczuk, tilapia,
              mintaj etc.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Owoce morza, czyli krewetki, kalmary itd.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Nabiał, czyli chudy twaróg, serek wiejski, jogurty (np. Skyr) itd.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Odżywka białkowa (najlepiej izolat białka)
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Roślinne źródła białka, np. strączki, nasiona, orzechy, tofu.
              Natomiast w tym wypadku należy pamiętać, że te produkty zawierają
              również więcej węglowodanów i tłuszczów.
            </p>
          </li>
        </ul>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          <snap style={{ color: "var(--color-purple" }}>TŁUSZCZE</snap> -
          odpowiednia ich ilość w codziennej diecie jest niezbędna do tego, aby
          nasz organizm funkcjonował prawidłowo. Zarówno jeśli chodzi o
          gospodarkę hormonalną jak i wchłanialność witamin rozpuszczalnych w
          tłuszczach. 1g tłuszczu to 9 kcal.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Ważne jest również jaki rodzaj tłuszczów będziemy wybierać. Oprócz
          nasyconych kwasów tłuszczowych, powinniśmy pamiętać o
          wielo-nienasyconych kwasach tłuszczowych. Minimum 4% energii powinno
          pochodzić z kwasu linolowego (omega-6), którego źródłem będą na
          przykład:
        </p>
        <ul className="montserrat-md medium grey-700">
          <li>
            <p className="montserrat-md medium grey-700">Orzechy</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Pestki i nasiona</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Oleje roślinne</p>
          </li>
        </ul>
        <div style={{ height: 0 }}></div>
        <p className="montserrat-md medium grey-700">
          Następnie, około 0,5% z omega-3, który zawierają:
        </p>
        <ul className="montserrat-md medium grey-700">
          <li>
            <p className="montserrat-md medium grey-700">Siemię lniane,</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Nasiona Chia,</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Olej lniany,</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Orzechy włoskie,</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Warto również spożywać tłuste ryby około 2 x w tygodniu.
            </p>
          </li>
        </ul>
        <div style={{ height: 16 }}></div>
        <h2 className="display-xs medium blue-text">
          Ile tłuszczów powinniśmy spożywać?
        </h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Zarówno osoby aktywne jak i te mniej, powinny uwzględniać około 20-35%
          tłuszczów względem całkowitej kaloryczności diety.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Policzmy więc zapotrzebowanie na tłuszcze dla Kasi, przyjmując, że
          powinny stanowić 30% całej kaloryczności.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          1870,66 kcal x 30% = 561,198
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">561,198 : 9 = 62,3 g</p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          <snap style={{ color: "var(--color-purple" }}>WĘGLOWODANY</snap> -
          główne źródło energii dla naszego organizmu. Działają na niego jak
          paliwo. Magazynowane są w mięśniach jako glikogen. Zawierają błonnik,
          co niezbędne jest w prawidłowym funkcjonowaniu jelit. Podobnie jak
          białko, 1g węglowodanów to 4kcal.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Ich ilość w diecie będzie uzależniona od wielu czynników. Przede
          wszystkim dopasowana do wydatków energetycznych związanych z
          aktywnością. Zdecydowanie więcej węglowodanów powinien jeść
          przykładowo sportowiec, trenujący dwa razy dziennie, niż osoba, która
          ma siedzący tryb pracy i ćwiczy 3 x w tygodniu przez 1,5h.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Najlepiej bazować na produktach jak najmniej przetworzonych. To one
          powinny stanowić większość naszego jadłospisu. Dzięki temu, że mają
          większą objętość, będą dawały uczucie sytości na dłużej. To znaczy -
          bardziej nasycimy się wybierając worek kaszy, niż drożdżówkę.
          Przykładowe źródła węglowodanów:
        </p>
        <ul className="montserrat-md medium grey-700">
          <li>
            <p className="montserrat-md medium grey-700">Owoce</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Ryże, kasze etc.</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Olej lniany,</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Pieczywo pełnoziarniste
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Wafle ryżowe, pieczywo chrupkie
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Płatki owsiane, jaglane etc.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Makarony pełnoziarniste
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">Ziemniaki</p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Warzywa, rośliny strączkowe.
            </p>
          </li>
        </ul>
        <div style={{ height: 16 }}></div>
        <h2 className="display-xs medium blue-text">
          Jak najlepiej obliczyć zapotrzebowanie na węglowodany?
        </h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Jeśli jesteśmy po prostu aktywni, powinniśmy obliczyć ich ilość biorąc
          pod uwagę resztę makroskładników. To znaczy - znamy kaloryczność
          diety, wiemy ile białka i tłuszczów powinniśmy przyjmować - pozostałą
          część kalorii możemy przeznaczyć na węglowodany.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Wróćmy do przykładu Kasi:
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Kaloryczność:{" "}
          <snap style={{ color: "var(--color-purple" }}>1870,66 kcal</snap>
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Białko: 120g ={" "}
          <snap style={{ color: "var(--color-purple" }}>480 kcal</snap>
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Tłuszcze: 62,3g ={" "}
          <snap style={{ color: "var(--color-purple" }}>561,198 kcal</snap>
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Węglowodany:{" "}
          <snap style={{ color: "var(--color-purple" }}>
            1870,66 - (480 + 561,198) = 829,462 kcal = 207,3g
          </snap>
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Reasumując, tak wygląda tzw. zero kaloryczne na przykładzie Kasi wraz
          z podziałem makroskładników:
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          E: 1870 kcal B: 120g T: 62,3g W: 207,3g
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Pamiętajmy o tym, że powyższe obliczenia są szacunkowe. Nie da się
          wszystkiego dokładnie obliczyć, ale stworzenie takiej podstawy może
          pomóc nam w oszacowywaniu ilości ﻿spożywanych kalorii.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Tyczy się to także liczenia makroskładników. Niemożliwe jest liczenie
          wszystkiego co do grama, więc spokojnie możecie ustalić sobie pewien
          margines błędu. Nic się nie stanie jeśli jednego dnia zjemy
          przykładowo 130g białka, a innego 110. Najważniejsze będzie po prostu
          obserwowanie swojego organizmu.
        </p>
        <div style={{ height: 24 }}></div>
        <h2 className="display-xs medium blue-text">
          Co powinna zrobić Kasia żeby schudnąć?
        </h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Zacznijmy od tego, że Kasia prawdopodobnie większość czasu jadła
          więcej niż swoje podstawowe zapotrzebowanie kaloryczne, patrząc na to
          jakich wyborów żywieniowych dokonywała dotychczas i jak źle
          oszacowywała kaloryczność swoich posiłków. Zanim więc zacznie od
          ucinania kalorii, lepszym rozwiązaniem na samym początku będzie
          trzymanie się kaloryczności równej jej zeru kalorycznemu przez okres
          około 2 tygodni. Może okazać się, że dzięki temu już zacznie gubić
          kilogramy.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Ważne jest aby zacząć chudnąć „jak najmniejszym kosztem”, czyli
          starajmy się nie wprowadzać zbyt dużo czynników na raz.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Nie obcinajmy od razu kalorii, nie narzucajmy zbyt dużej ilości
          treningów na przestrzeni tygodnia. Obserwujmy jak reaguje nasz
          organizm podczas tych dwóch tygodniu i dopiero po tym czasie, jeśli
          nie zauważymy żadnej różnicy czy spadku wagi - zacznijmy ucinać
          kalorie.
        </p>
        <div style={{ height: 24 }}></div>
        <h2 className="display-xs medium blue-text">Redukcja</h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Przechodzimy do tematu, który podejrzewam interesuje przynajmniej 90%
          Sarenek. Jak stracić zbędne kilogramy? Kluczem oczywiście jest deficyt
          kaloryczny. Wyżej obliczyliśmy jakie jest nasze zero kaloryczne, to
          teraz omówimy jak zacząć redukcję z głową.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Jak już wcześniej wspomniałam, rozpoczynając dietę redukcyjną
          powinniśmy starać się schudnąć jak najmniejszym kosztem. Mam na myśli
          między innymi to, że nie powinniśmy od samego początku narzucać sobie
          zbyt dużego reżimu zarówno jeśli chodzi o liczbę spożywanych kalorii
          jak i ilość treningów.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Jeśli osoba, która dotychczas była bardzo mało aktywna fizycznie, a na
          co dzień w jej diecie pojawiały się fast foody i słodycze zacznie
          chodzić na siłownię 6 razy w tygodniu, a zamiast normalnych posiłków
          postawi na modną dietę sokową, szybko wróci do starych nawyków. Zapał
          do treningów spadnie z powodu zbyt niskiej podaży energii i cała
          przygoda z siłownią skończy się po tygodniu.. w najlepszym wypadku. I
          znowu powrót do punktu wyjściowego.
        </p>
        <div style={{ height: 24 }}></div>
        <h2 className="display-xs medium blue-text">
          Ale zanim przejdziemy do liczb, omówmy od czego zacząć i o czym
          pamiętać.
        </h2>
        <div style={{ height: 24 }}></div>
        <ul className="montserrat-md medium grey-700">
          <li>
            <p className="montserrat-md medium grey-700">
              Wszelkiego rodzaju głodówki,
              <snap style={{ color: "var(--color-purple" }}>
                {" "}
                diety grejpfrutowe, kapuściane, kopenhaskie, dukana i inne
                powinniśmy omijać szerokim łukiem.{" "}
              </snap>
              Jak już wyżej wspomniałam - „dieta” nie ma by katorgą i nie
              powinna wykluczać zbyt dużo produktów jednocześnie. Popadanie ze
              skrajności z skrajność nigdy nie sprawdzi się na dłuższą metę.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              <snap style={{ color: "var(--color-purple" }}>
                {" "}
                Sposób odżywiania powinien być ZINDYWIDUALIZOWANY.
              </snap>{" "}
              To znaczy, że powinien brać pod uwagę nasze konkretne potrzeby,
              upodobania, parametry, co{" "}
              <snap style={{ color: "var(--color-purple" }}>
                {" "}
                pozwoli na kontynuowanie tego sposobu odżywiania przez dłuższy
                czas i pomoże uzyskać efekty na dłuższy czas, a nie tylko na
                chwilę.
              </snap>
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              Efekty. Każdy chciałby zobaczyć efekty JUŻ, najlepiej po trzech
              dniach treningów. Miejmy jednak na uwadze, że{" "}
              <snap style={{ color: "var(--color-purple" }}>
                {" "}
                im szybsze tempo utraty masy ciała, tym większe ryzyko
                wystąpienie zaburzeń hormonalnych, ubytku LBM, niedoborów
                pokarmowych oraz – trudności z opanowaniem łaknienia.
              </snap>{" "}
              Kobiecy organizm łatwiej „załatwić” złą dietą niż ciężkim
              treningiem, co skutkować może poważnymi problemami zdrowotnymi w
              przyszłości. Jakie więc będzie optymalne tempo odchudzania? Dla
              większości odchudzających się osób będzie to tempo, które zakłada
              utratę od 0,5 do 1% w skali tygodnia (zmiany w całkowitej masie
              ciała). To dość szeroki przedział, więc należy podkreślić́, że
              górny zakres jest optymalny dla osób o wyższym poziomie
              zatłuszczenia i nadwagą, natomiast dolny – dla osób, które mają
              niższy poziom tkanki tłuszczowej.
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              <snap style={{ color: "var(--color-purple" }}>
                {" "}
                Warto jest wyznaczyć sobie REALNY cel.
              </snap>{" "}
              To znaczy nie taki, który zakłada utratę 20kg i 20cm w biodrach w
              miesiąc, bo w ten sposób znowu narzucamy sobie pewien reżim.
              Prędzej czy później doprowadzi to do frustracji i zdemotywuje do
              kontynuowania planu.
            </p>
          </li>
        </ul>
        <div style={{ height: 24 }}></div>
        <h2 className="display-xs medium blue-text">
          Ustalenie deficytu kalorycznego
        </h2>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Czas przejść do konkretów i ustalić kaloryczność naszej diety
          redukcyjnej. Restrykcje energetyczne nie powinny być zbyt głębokie, bo
          prędzej czy później pojawi się trudny do opanowania głód, co
          poskutkuje przerwaniem diety. Natomiast w drugą stronę, jeśli deficyt
          nie będzie wystarczający, wówczas nie osiągniemy zamierzonych efektów.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          To kwestia bardzo indywidualna, bo osoba z nadwagą, która ma bardzo
          duży % tkanki tłuszczowej będzie mogła zastosować śmielsze cięcia tj.
          20-30%, natomiast dla osoby, która już ma mało tłuszczu, nawet 15%
          może być już zbyt dużym deficytem na początku redukcji.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          W większości przypadków ograniczenie spożycia energii o około 10 – 20%
          będzie rozwiązaniem najkorzystniejszym z punktu widzenia optymalizacji
          przebiegu redukcji tłuszczu zapasowego. Wraz z biegiem redukcji i
          ewentualnymi okresami stagnacji można pogłębiać deficyt, ale w
          początkowej fazie starajmy się zrobić to „jak najmniejszym kosztem”,
          żeby w późniejszym czasie było „z czego” obciąć te kalorie.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          W praktyce - jeśli zaczniemy redukcję od 1500kcal, schudniemy 5kg i
          pomimo trzymania się tej kaloryczności nie będziemy zauważać dalszego
          spadku wagi, pogłębiając deficyt i schodząc poniżej 1500 kcal możemy
          nabawić się różnego rodzaju niedoborów, co skutkować może zaburzeniami
          w pracy naszego organizmu, hormonów i wielu innych.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Wracając do przykładu Kasi:
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Jej zero kaloryczne wynosi 1870 kcal
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Ograniczając spożycie energii o ok. 15% liczba spożywanych kalorii
          będzie wynosić około 1589,5 kcal.
        </p>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Wiesz już jak ustalić swój deficyt? Zanotuj, żeby nie zapomnieć!
        </p>
        <div style={{ height: 64 }}></div>
      </div>
    ),
  },
  9: {
    id: 9,
    tag: "Dieta",
    mainTitle: "Nawyki",
    description:
      "Na rynku jest dostępnych tysiące książek o tym jak istotne dla naszego rozwoju jest kształtowanie nawyków. W nawiązaniu do wrześniowego ...",
    leftImage:
      "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhabits%2Fhabits_main.png?alt=media&token=d19dffac-d8c1-4e43-b520-227cec738158",
    cellTitle: "Dagmara Wawrzyniak",
    cellSubtitle: "23 styczeń 2024",
    content: (
      <div>
        <h1 className="display-md medium blue-text">
          Na rynku jest dostępnych tysiące książek o tym jak istotne dla naszego
          rozwoju jest kształtowanie nawyków. W nawiązaniu do wrześniowego
          wyzwania SHAPE FACTOR „Kształtujemy zdrowe nawyki” przyjrzyjmy się
          całemu procesowi powstawania nawyku oraz wyjaśnijmy dlaczego odgrywa
          on kluczową rolę w naszym codziennym życiu.
        </h1>
        <div style={{ height: 24 }}></div>
        <div style={{ height: 24 }}></div>
        <h1 className="display-md medium blue-text">
          Co to w ogóle jest cały ten NAWYK?
        </h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md medium grey-700">
          Nawyk to automatyczny, powtarzalny wzorzec zachowania, który
          kształtuje się przez regularne wykonywanie danej czynności. Może
          dotyczyć zarówno działań pozytywnych, jak i negatywnych. Przykładowe
          nawyki:
        </p>
        <ul className="montserrat-md medium grey-700">
          <li>
            <p className="montserrat-md medium grey-700">
              mycie zębów każdego ranka,
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              mycie zębów każdego ranka,
            </p>
          </li>
          <li>
            <p className="montserrat-md medium grey-700">
              objadanie się słodyczami przed snem.
            </p>
          </li>
        </ul>
        <p className="montserrat-md medium grey-700">
          Proces formowania nawyku zazwyczaj wymaga czasu i konsekwencji. Istotą
          nawyku jest to, że po pewnym czasie staje się on naturalną częścią
          codziennego życia, wykonywaną bez konieczności świadomego podejmowania
          decyzji. Kształtowanie dobrych nawyków może znacząco wpłynąć na
          poprawę jakości życia i osiąganie osobistych celów.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Kluczem do skutecznego kształtowania nawyków jest zrozumienie
          mechanizmu, na którym się opierają:
        </p>
        <div style={{ height: 32 }}></div>
        <p className="display-xs medium primary-text text-center">
          WYZWALACZ -> RUTYNA -> NAGRODA
        </p>
        <div style={{ height: 32 }}></div>
        <p className="montserrat-md medium grey-700">
          Wyzwalacz to bodziec, który inicjuje działanie, rutyna to czynność, a
          nagroda to korzyść, jaką z niej czerpiemy. Aby stworzyć nowy nawyk,
          warto zacząć od małych, łatwych do zrealizowania kroków i stopniowo
          zwiększać ich złożoność. Konsekwencja w działaniu jest tu kluczowym
          elementem. Badania pokazują, że potrzeba średnio 21 do 66 dni, aby
          nowa czynność stała się nawykiem.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Nawyki umożliwiają automatyzację codziennych czynności, co pozwala
          zaoszczędzić czas i energię, które można przeznaczyć na bardziej
          złożone zadania. Dzięki dobrze ukształtowanym nawykom, można również
          zwiększyć swoją efektywność i produktywność. Na przykład, regularne
          planowanie dnia czy systematyczne wykonywanie ćwiczeń fizycznych może
          prowadzić do poprawy zdrowia fizycznego i psychicznego. Ponadto,
          pozytywne nawyki sprzyjają rozwojowi osobistemu, pomagając w osiąganiu
          długoterminowych celów i wzmocnieniu samodyscypliny, co następnie
          wiąże się z podwyższeniem naszej samooceny oraz pewności siebie,
          ponieważ wywiązaliśmy się z postawionym sobie zadań. Warto również
          pamiętać, że nawyki mają tendencję do kumulowania się, co oznacza, że
          małe, codzienne działania prowadzą do znaczących zmian w życiu w
          dłuższej perspektywie.
        </p>
        <div style={{ height: 32 }}></div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/blog%2Fhabits%2Fhabits1.png?alt=media&token=fab96782-6d2b-452a-9d5a-e204a87e8933"
          style={{ maxWidth: "100%", borderRadius: "12px" }}
        ></img>
        <div style={{ height: 32 }}></div>
        <h1 className="display-md medium blue-text">
          Jak kształtować zdrowe nawyki i dlaczego warto?
        </h1>
        <div style={{ height: 24 }}></div>
        <p className="montserrat-md semibold grey-700">
          Budowanie zdrowych nawyków to proces, który wymaga czasu,
          zaangażowania i samodyscypliny, ale efekt, jaki przynosi, jest
          bezcenny.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold grey-700">
          Badania pokazują, że osoby regularnie dbające o swoje zdrowie –
          poprzez odpowiednią dietę, aktywność fizyczną czy odpoczynek – mają
          więcej energii, lepsze samopoczucie i są mniej podatne na stres oraz
          choroby cywilizacyjne, takie jak cukrzyca, otyłość czy depresja.
          Zdrowe nawyki mogą też poprawić jakość snu i relacje z innymi ludźmi,
          a także wpływać na nasze sukcesy zawodowe i osobiste.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold grey-700">
          1. Zacznij od małych kroków. Zamiast obiecywać sobie, że od teraz
          będziesz codziennie chodzić na siłownię, zacznij od spacerów lub
          krótkich treningów w domu.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold grey-700">
          2. Zamiast wprowadzać drastyczne zmiany, koncentruj się na małych,
          stopniowych modyfikacjach, np. zamień słodkie napoje na wodę, zamiast
          windy wybieraj schody.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold grey-700">
          3. Monitorowanie postępów jest istotne, aby zaobserwować swój progres.
          Zapisz swoje postępy w notatniku lub korzystaj z aplikacji mobilnych,
          które pomogą Ci śledzić codzienne aktywności. Widząc postępy, zyskasz
          motywację do dalszego działania.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold grey-700">
          4. Wspólne kształtowanie nawyków z bliskimi lub znajomymi może być
          łatwiejsze. Wzajemne wsparcie motywuje do działania i pomaga w
          utrzymaniu motywacji.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md semibold grey-700">
          5. Każdy mały sukces to krok w dobrą stronę, dlatego warto się
          nagradzać – niekoniecznie słodyczami, ale np. czasem na relaks czy
          przyjemnym wyjściem do kina.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Budowanie zdrowych nawyków to inwestycja w przyszłość. Choć zmiana
          może wymagać wysiłku, to po pewnym czasie zdrowe wybory staną się
          automatyczne. Dzięki temu będziemy bardziej odporni na stres, choroby,
          a także będziemy mogli cieszyć się dłuższym i lepszym życiem.
          Pamiętajmy, że zdrowe nawyki nie tylko wpływają na nasze ciało, ale
          kształtują też naszą pewność siebie i jakość życia.
        </p>
        <div style={{ height: 16 }}></div>
        <p className="montserrat-md medium grey-700">
          Zdrowy styl życia jest w zasięgu ręki każdego z nas. Wystarczy zacząć,
          a efekty z czasem pojawią się same. Ważne jest, aby być wyrozumiałym
          dla siebie i nie zniechęcać się drobnymi potknięciami. Warto pamiętać,
          że każdy mały krok w kierunku pozytywnej zmiany to krok ku lepszemu
          życiu.
        </p>
        <div style={{ height: 32 }}></div>
        <p className="text-md medium primary-text text-center">
          WPROWADZENIE ZDROWYCH NAWYKÓW TO NAJLEPSZY FUNDAMENT, ZACHOWANIA
          EFEKTÓW SWOJEJ PRACY NA LATA
        </p>
        <div style={{ height: 48 }}></div>
      </div>
    ),
  },
};

export default blogData;
