import React from "react";
import "../../../styles/components/programDetails/ProgramDetails.css";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextWithToggle from "../../textWithToggle/TextWithToggle";

const ProgramDetails = ({
  image,
  backgroundImage,
  modulesCount,
  trainingHours,
  difficultyLevel,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div className="program-details">
      <div className="program-image-section">
        <div className="program-image-container">
          <img src={image} alt="Program" className="program-image" />
          <div className="program-stats">
            <div className="stat-item">
              <img
                src="./icons/program-camera.svg"
                alt="icon"
                className="icon-module"
              />
              <p className="montserrat-sm semibold">{modulesCount} Treningi</p>
            </div>
            <div className="stat-item">
              <img src="./icons/clock.svg" alt="icon" className="icon-module" />
              <p className="montserrat-sm semibold">
                {trainingHours} godzin treningu
              </p>
            </div>
            <div className="stat-item">
              <img
                src="./icons/lightning.svg"
                alt="icon"
                className="icon-module"
              />{" "}
              <p className="montserrat-sm semibold">{difficultyLevel}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="program-info-section">
        <div className="more-info-block">
          <h3 className="display-xs medium">O programie</h3>
          <TextWithToggle text={description} maxLength={150} />
          <div className="btn-widht">
            <PrimaryButton text={"Zacznij trening"} onClick={onButtonClick} />
          </div>
        </div>

        <div className="desktop-only-block">
          <h3 className="display-xs medium">O programie</h3>
          <TextWithToggle
            text={description}
            maxLength={2000}
            maxLine={10}
            whiteSpaceAlways={true}
          />
          <div className="btn-widht">
            <PrimaryButton text={"Zacznij trening"} onClick={onButtonClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramDetails;
