import { getRequest } from "../HttpService";

export const fetchOrders = async () => {
  try {
    const response = await getRequest("/api/orders");
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchOrderById = async (id) => {
  try {
    const response = await getRequest(`/api/orders/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
